import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockRootRoute, ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute } from "@ng-stack/api-mock";

export class SectionApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            "id": 1,
            "questions": [
                3
            ],
            "title": "General Information"
        },
        {
            "id": 2,
            "questions": [
                5,
                6,
                7,
                8,
                9,
                10,
                11
            ],
            "title": "Storekeeper Information"
        },
        {
            "id": 3,
            "questions": [],
            "title": "General Assessment"
        },
        {
            "id": 4,
            "questions": [
                12,
                13,
                14,
                15
            ],
            "title": "Environment Agency"
        },
        {
            "id": 5,
            "questions": [
                16,
                17,
                18
            ],
            "title": "Fire Authority"
        },
        {
            "id": 6,
            "questions": [
                19,
                20,
                21,
                22,
                23
            ],
            "title": "Emergency Contingency Plan"
        },
        {
            "id": 7,
            "questions": [
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
                32
            ],
            "title": "Fire Precautions"
        },
        {
            "id": 8,
            "questions": [
                33,
                34,
                35,
                36,
                202,
                203
            ],
            "title": "General Security"
        },
        {
            "id": 9,
            "questions": [
                37,
                38,
                39,
                40,
                41,
                42,
                43,
                44,
                45,
                46
            ],
            "title": "Health & Safety"
        },
        {
            "id": 10,
            "questions": [
                47
            ],
            "title": "Reference for Approved Pesticides"
        },
        {
            "id": 11,
            "questions": [
                48,
                49,
                50,
                51,
                52,
                53,
                54,
                55
            ],
            "title": "Poisons Rules"
        },
        {
            "id": 12,
            "questions": [
                56,
                57,
                58,
                59,
                60,
                61
            ],
            "title": "Poisons storage"
        },
        {
            "id": 13,
            "questions": [
                62
            ],
            "title": "Rodenticides"
        },
        {
            "id": 14,
            "questions": [],
            "title": "The Pesticide Store"
        },
        {
            "id": 15,
            "questions": [
                63,
                64,
                65,
                66,
                67,
                68,
                69,
                70,
                71,
                72,
                73,
                74,
                75,
                76,
                77,
                78,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                86,
                87
            ],
            "title": "Siting"
        },
        {
            "id": 16,
            "questions": [
                88,
                89,
                204
            ],
            "title": "Ventilation"
        },
        {
            "id": 17,
            "questions": [
                90,
                91,
                92,
                205
            ],
            "title": "Lighting"
        },
        {
            "id": 18,
            "questions": [
                93,
                94,
                95,
                96,
                97,
                223
            ],
            "title": "Signs"
        },
        {
            "id": 19,
            "questions": [
                98,
                99,
                100,
                101,
                102,
                103,
                104,
                105
            ],
            "title": "Extinguishers"
        },
        {
            "id": 20,
            "questions": [
                106,
                110,
                114,
                116,
                119,
                213,
                107,
                108,
                109,
                214,
                111,
                112,
                113,
                215,
                115,
                216,
                117,
                118,
                217,
                120,
                121,
                122,
                123,
                251,
                179,
                255,
                177,
                252,
                273,
                253,
                254,
                274,
                256
            ],
            "title": "PPE"
        },
        {
            "id": 21,
            "questions": [
                124,
                125,
                126,
                127,
                128,
                129,
                130,
                131,
                132,
                133,
                134,
                135,
                136,
                137,
                138,
                139,
                140
            ],
            "title": "Segregation of Pesticides"
        },
        {
            "id": 22,
            "questions": [
                141,
                142
            ],
            "title": "Separate Storage"
        },
        {
            "id": 23,
            "questions": [
                143,
                144
            ],
            "title": "Stacking Guidelines"
        },
        {
            "id": 24,
            "questions": [
                145,
                146,
                147,
                148,
                149,
                150,
                151,
                153,
                154,
                155
            ],
            "title": "Damaged Stock"
        },
        {
            "id": 25,
            "questions": [
                156,
                157
            ],
            "title": "Site"
        },
        {
            "id": 26,
            "questions": [
                158,
                159,
                160
            ],
            "title": "Loading/Unloading Area"
        },
        {
            "id": 27,
            "questions": [],
            "title": "Transport - DGSA"
        },
        {
            "id": 28,
            "questions": [
                161,
                162,
                163
            ],
            "title": "DGSA"
        },
        {
            "id": 29,
            "questions": [],
            "title": "Transport - non-DGSA"
        },
        {
            "id": 30,
            "questions": [
                164,
                165,
                166,
                167,
                168,
                169,
                170,
                171,
                172,
                173,
                174,
                175,
                176
            ],
            "title": "Vehicle(s)"
        },
        {
            "id": 31,
            "questions": [
                178,
                180,
                181,
                182,
                183,
                184,
                186,
                187,
                188,
                189,
                190,
                191,
                192,
                194
            ],
            "title": "Vehicle Safety Items"
        },
        {
            "id": 32,
            "questions": [
                195,
                196,
                197,
                198
            ],
            "title": "Driver Training"
        },
        {
            "id": 33,
            "questions": [
                199
            ],
            "title": "Off-Loading"
        },
        {
            "id": 34,
            "questions": [],
            "title": "Seed Treatment Premises"
        },
        {
            "id": 35,
            "questions": [
                200,
                201,
                231,
                232,
                233
            ],
            "title": "Treatment Area"
        },
        {
            "id": 36,
            "questions": [
                206,
                207,
                208,
                209,
                210,
                211,
                212
            ],
            "title": "Containment"
        },
        {
            "id": 37,
            "questions": [
                218,
                219,
                220,
                221,
                152,
                222
            ],
            "title": "Spill Kit"
        },
        {
            "id": 38,
            "questions": [
                224,
                225,
                226
            ],
            "title": "Fire Extinguishers"
        },
        {
            "id": 39,
            "questions": [
                227,
                228,
                229,
                230
            ],
            "title": "Operator Safety Precautions"
        },
        {
            "id": 40,
            "questions": [],
            "title": "Seed Treatment Mobile"
        },
        {
            "id": 41,
            "questions": [
                234
            ],
            "title": "Information"
        },
        {
            "id": 42,
            "questions": [
                235,
                238,
                239,
                241,
                263,
                236,
                237,
                264,
                265,
                240,
                266
            ],
            "title": "Documentation"
        },
        {
            "id": 43,
            "questions": [
                242,
                243,
                245,
                247,
                248
            ],
            "title": "Mobile Seed Treaters"
        },
        {
            "id": 44,
            "questions": [
                249,
                250
            ],
            "title": "Operators"
        },
        {
            "id": 45,
            "questions": [
                258,
                260,
                261,
                257,
                185,
                259,
                275,
                276,
                193
            ],
            "title": "Transport of Pesticides"
        },
        {
            "id": 46,
            "questions": [
                277,
                278,
                262
            ],
            "title": "Pesticide Waste"
        },
        {
            "id": 47,
            "questions": [],
            "title": "Contractors"
        },
        {
            "id": 48,
            "questions": [
                267,
                268,
                269,
                244,
                270,
                246,
                271,
                272
            ],
            "title": "Application Vehicles"
        },
        {
            "id": 49,
            "questions": [],
            "title": "Additional BACCS Store/contractor section"
        },
        {
            "id": 50,
            "questions": [
                279,
                280,
                281,
                282,
                283,
                284
            ],
            "title": "Environmental Management Policy"
        },
        {
            "id": 51,
            "questions": [
                285
            ],
            "title": "Advisory Staff"
        },
        {
            "id": 52,
            "questions": [
                286,
                287,
                288,
                289,
                290,
                291
            ],
            "title": "Staff Training"
        },
        {
            "id": 53,
            "questions": [
                292,
                293,
                294,
                295,
                296
            ],
            "title": "Quality Assurance"
        },
        {
            "id": 54,
            "questions": [
                297
            ],
            "title": "Complaints"
        },
        {
            "id": 55,
            "questions": [],
            "title": "Audit Summary"
        },
        {
            "id": 56,
            "questions": [],
            "title": "Assessor Overall Audit Summary"
        },
        {
            "id": 57,
            "questions": [
                299
            ],
            "title": "Inspection Scheme completion time"
        },
        {
            "id": 58,
            "questions": [],
            "title": "Self Audit Check"
        },
        {
            "id": 59,
            "questions": [
                300,
                301,
                302,
                303,
                304,
                305,
                306,
                307
            ],
            "title": "Self Audit additional information"
        },
        {
            "id": 60,
            "questions": [],
            "title": "CRRU Point of Sale Audit"
        },
        {
            "id": 61,
            "questions": [
                308,
                309,
                310,
                311
            ],
            "title": "CRRU Point of Sale Audit General Information"
        },
        {
            "id": 62,
            "questions": [
                312,
                313,
                314,
                315,
                316,
                318
            ],
            "title": "CRRU POS Audit Users"
        },
        {
            "id": 63,
            "questions": [
                319,
                320,
                321,
                322,
                323
            ],
            "title": "CRRU POS Audit Farm Assurance"
        },
        {
            "id": 64,
            "questions": [
                324,
                325,
                317,
                326
            ],
            "title": "CRRU POS Audit Supply Chain"
        },
        {
            "id": 65,
            "questions": [
                298
            ],
            "title": "POS Audit Grade"
        },
        {
            "id": 66,
            "questions": [],
            "title": "Audit information 2"
        },
        {
            "id": 67,
            "questions": [
                327
            ],
            "title": "POS Audit Information 2"
        },
        {
            "id": 68,
            "questions": [
                328
            ],
            "title": "Test Section"
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return SectionApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
            else if (httpMethod == 'POST') {
                console.log("entro post")
            }
        }
    }
}
