import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockRootRoute, ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute } from "@ng-stack/api-mock";

export class SchemeApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            "id": 1,
            "sections": [
                57,
                24,
                6,
                4,
                19,
                5,
                7,
                1,
                8,
                9,
                17,
                26,
                11,
                12,
                20,
                10,
                13,
                21,
                22,
                18,
                25,
                15,
                23,
                2,
                16
            ],
            "title": "Distributor Store (PII)"
        },
        {
            "id": 2,
            "sections": [
                57,
                28,
                30,
                31,
                32,
                33,
                1,
                2
            ],
            "title": "Transport of Pesticides (TO)"
        },
        {
            "id": 3,
            "sections": [
                57,
                1,
                2,
                4,
                5,
                6,
                7,
                8,
                9,
                28,
                30,
                31,
                32,
                33,
                35,
                16,
                17,
                36,
                20,
                37,
                18,
                38,
                39,
                41,
                42,
                43,
                44,
                45,
                46
            ],
            "title": "Seed Treatment (PIII)"
        },
        {
            "id": 4,
            "sections": [
                57,
                1,
                2,
                4,
                6,
                28,
                30,
                31,
                32,
                33,
                41,
                42,
                48,
                44,
                20,
                45,
                46,
                61
            ],
            "title": "Contractor (PIV)"
        },
        {
            "id": 5,
            "sections": [
                1
            ],
            "title": "Cut Agronomy"
        },
        {
            "id": 6,
            "sections": [
                5,
                68
            ],
            "title": "Test Scheme"
        },
        {
            "id": 7,
            "sections": [
                4,
                3
            ],
            "title": "test2 scheme"
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return SchemeApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
            else if (httpMethod == 'POST') {
                console.log("entro post")
            }
        }
    }
}
