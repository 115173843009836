import { Injectable } from '@angular/core';
import { Notification } from '../interfaces/provider';
import { HttpClient } from '@angular/common/http';
import { API, HEADERS } from './constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationMenuService {
  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private notification: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(
    JSON.parse(sessionStorage.getItem('notifications'))
  );

  constructor(private http: HttpClient) {}

  setHidden(hidden: boolean) {
    this.hidden.next(hidden);
  }

  getHidden(): boolean {
    return this.hidden.getValue();
  }

  setNotification(notification: Notification[]) {
    console.log(notification);
    this.notification.next(notification);
  }

  getNotification(): Observable<Notification[]> {
    return this.notification.asObservable();
  }

  requestLoadNotification(): Observable<any> {
    return this.http
      .get(API + '/member/invite', {
        headers: HEADERS,
        observe: 'response',
      })
      .pipe(
        map(response => {
          if (response.status === 200) {
            let notifications: Notification[] = [];
            let body: any = response.body;
            body.forEach(notification => {
              notifications.push({
                identifier: notification.identifier,
                subject: notification.org_name,
                message: 'JOIN AS EMPLOYEE',
                attr: notification,
              });
            });
            this.setNotification(notifications);
            sessionStorage.setItem('notifications', JSON.stringify(notifications));
          } else if (response.status === 204) {
            this.setNotification([]);
            sessionStorage.setItem('notifications', JSON.stringify([]));
          }
          console.log('servicio', response);
          return true;
        })
      );
  }

  requestGetInvitations(): Observable<any> {
    return this.http.get(API + '/member/invite', {
      headers: HEADERS,
      observe: 'response',
    });
  }

  requestAcceptInvitations(identifier: string): Observable<any> {
    return this.http.put(
      API + '/member/invite/' + identifier,
      {},
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  requestRejectInvitations(identifier: string): Observable<any> {
    return this.http.delete(API + '/member/invite/' + identifier, {
      headers: HEADERS,
      observe: 'response',
    });
  }
}
