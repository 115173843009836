import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SplashService {
  constructor() {}

  setSplash(isDisplay: boolean): void {
    let display = isDisplay ? 'flex' : 'none';
    document.getElementById('loadingContainer').style.display = display;
  }
}
