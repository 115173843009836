import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackdropService {

  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  setHidden(hidden: boolean ){
    this.hidden.next(hidden);
  }
 
  getHidden(): boolean{
    return this.hidden.getValue();
  } 
}
