import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private modalData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  setHidden(hidden: boolean) {
    this.hidden.next(hidden);
  }

  getHidden(): Observable<boolean> {
    return this.hidden.asObservable();
  }

  setModalData(modalData: any) {
    this.modalData.next(modalData);
  }

  getModalData(): any {
    return this.modalData.getValue();
  }

  /**
   * Show the modal component.
   *
   * @param {string} title - Modal title.
   * @param {string} subtitle - Modal Subtitle.
   * @param {any} [action] - Action to executo on accept.
   * @returns {void}.
   */
  public showModal = (title: string, subtitle: string, action?: any): void => {
    const modal = this.generateModalData(title, subtitle, action);
    this.setModalData(modal);
    this.setHidden(false);
  };

  public hideModal = (): void => {
    this.setHidden(true);
  };

  /**
   * Generate the data required by the modal.
   *
   * @param {string} title - Modal title.
   * @param {string} subtitle - Modal Subtitle.
   * @param {any} [action] - Action to execute on accept.
   * @returns {any}.
   */
  private generateModalData = (
    title: string,
    subtitle: string,
    action?
  ): any => {
    let numberOfButtons = 1;
    const button = [
      {
        label: 'ACCEPT',
        type: 'primary',
        enable: true,
      },
    ];
    let otherButtons = undefined;
    let onSubmit = this.setHidden.bind(this, true);

    if (action) {
      numberOfButtons = 2;
      button.unshift({
        label: 'CANCEL',
        type: 'secondary',
        enable: true,
      });
      otherButtons = this.setHidden.bind(this, true);
      onSubmit = action;
    }

    const modalData = {
      titleLabel: title,
      subtitleLabel: subtitle,
      numberOfButtons: numberOfButtons,
      button: button,
      otherButtons: otherButtons,
      onSubmit: onSubmit,
    };
    return modalData;
  };
}
