import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, mergeMap, tap } from 'rxjs/operators';
import { API, HEADERS } from 'src/app/services/constants';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { Membership } from 'src/app/models/store/membership';
import { Section } from 'src/app/models/store/section';
import { StoreSectionService } from './store-section.service';
import { StoreIdentifierService } from './store-identifier.service';
import { Identifier } from 'src/app/models/store/identifier';
import { DataStoreService } from '../datastore.service';

@Injectable({
    providedIn: 'root'
})
export class StoreMembershipService extends DataStoreService<Membership> {

    constructor(
        protected http: HttpClient,
        private storeSectionService: StoreSectionService,
        private storeIdentifierService: StoreIdentifierService
    ) {
        super(http, 'memberships')
    }

    getAll(apiRequest: ApiRequest<Membership>): Observable<Membership[]> {

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Membership[]> => {

                if(!response) return new BehaviorSubject<Membership[]>([])

                let memberships: Observable<Membership>[] = response.map((element): Observable<Membership> => {

                    const mapper = new ApiRequestMapper<Membership>(apiRequest);

                    mapper.setMapperKey('id', element.id);
                    mapper.setMapperKey('title', element.title);

                    let sectionsApiRequest = apiRequest.getStructureChild('sections');

                    if(sectionsApiRequest){
                        let sections: Observable<Section>[] = element.sections.map((section): Observable<Section> => {
                            return this.storeSectionService.getById(
                                section,
                                sectionsApiRequest
                            )
                        })
                        let combineSections = () => combineLatest(sections);
                        mapper.setMapperKey('sections', sections.length > 0? combineSections.bind(this):[]);
                    }

                    return mapper.map();

                })

                return combineLatest(memberships)
            })
        )

    }

    getById(id: number, apiRequest: ApiRequest<Membership>): Observable<Membership> {

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Membership> => {

                if(!response) return new BehaviorSubject<Membership>(null)

                let element = response.find(membership =>  membership.id == id)

                const mapper = new ApiRequestMapper<Membership>(apiRequest);

                mapper.setMapperKey('id', element.id);
                mapper.setMapperKey('title', element.title);

                let sectionsApiRequest = apiRequest.getStructureChild('sections');

                if(sectionsApiRequest){
                    let sections: Observable<Section>[] = element.sections.map((section): Observable<Section> => {
                        return this.storeSectionService.getById(
                            section,
                            sectionsApiRequest
                        )
                    })
                    let combineSections = () => combineLatest(sections)
                    mapper.setMapperKey('sections', sections.length > 0? combineSections.bind(this):[]);
                }

                return mapper.map();

            })
        )
    }

    create(membership: Membership){

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<any> => {
                return this.storeIdentifierService.increaseAndGetByName('memberships').pipe(
                    mergeMap((identifier: Identifier) => {

                        let membershipData = {
                            id: identifier.currentValue,
                            sections: membership.sections.map(section => section.id),
                            title: membership.title,
                        }
                        response.push(membershipData)

                        return this.http.post (
                            API + '/datastore/memberships',
                            response,
                            {
                                headers: HEADERS
                            }
                        )
                    }),
                    tap(() => {
                        this.setDataStore(response);
                    })
                )
            })
        )
    }

    deleteById(id: number){

        return this.getDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let datastoreFiltered = datastore.filter(membership => membership.id != id)

                return this.http.post (
                    API + '/datastore/memberships',
                    datastoreFiltered,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastoreFiltered);
                    })
                )
            })
        )
    }

    updateById(id: number, membership: Membership){

        return this.getDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {

                let membershipData  = datastore.find(membership => membership.id == id)
                membershipData.sections = membership.sections.map(section => section.id)
                membershipData.title = membership.title

                return this.http.post (
                    API + '/datastore/memberships',
                    datastore,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastore);
                    })
                )
            })
        )

    }

}
