import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-item-title-label',
  templateUrl: './dashboard-item-title-label.component.html',
  styleUrls: ['./dashboard-item-title-label.component.css']
})
export class DashboardItemTitleLabelComponent {

  @Input() data: any;

}
