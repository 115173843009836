import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentItem } from 'src/app/interfaces/provider';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent {

  @Input() field1: ComponentItem[];
  @ViewChild('field1', { read: ViewContainerRef }) fieldContainer1: ViewContainerRef;
  
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngAfterViewInit() {
    setTimeout(() => {
      
      this.field1.forEach(field => {
        const fieldResolver2 = this.componentFactoryResolver.resolveComponentFactory(field.component);
        const componentRef2 = this.fieldContainer1.createComponent(fieldResolver2);
        componentRef2.instance.data = field.data;
      })

    });
  }

}
