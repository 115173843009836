import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { BasicAuthInterceptor, ErrorInterceptor } from './interceptors/provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiMockModule } from '@ng-stack/api-mock';
import { IS_BACKEND_MIXED, IS_PRODUCTION } from 'src/environments/environment';
import { ApiMockBackendService } from './mockBackend/api-mock-backend.service';

/** Config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

import { NZ_DATE_LOCALE, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { enGB } from 'date-fns/locale';

const apiMockModule = ApiMockModule.forRoot(ApiMockBackendService, {
  passThruUnknownUrl: IS_BACKEND_MIXED,
  cacheFromLocalStorage: false,
  delay: 500,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    !IS_PRODUCTION ? apiMockModule : [],
    GoogleMapsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatMomentDateModule,
    MatSnackBarModule,
    NzNotificationModule,
  ],
  providers: [
    GoogleMap,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_LOCALE, useValue: enGB },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
