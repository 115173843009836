import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, mergeAll, mergeMap, tap } from 'rxjs/operators';
import { API } from 'src/environments/environment';
import { ApiRequest, ApiRequestMapper } from '../../models/apiRequest';
import { Identifier } from '../../models/store/identifier';
import { HEADERS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class StoreIdentifierService {

  constructor(private http: HttpClient) { }

  increaseAndGetByName(name: string): Observable<Identifier> {

    let foundIdentifier: Identifier;

    let apiRequest = new ApiRequest<Identifier>(
      `{
          name,
          currentValue,
          increment
      }`
    )

    return this.http.get(
      API + '/datastore/identifiers',
      {
        headers: HEADERS
      }
    ).pipe(
      mergeMap((response: any): Observable<Identifier[]> => {
        if (!response) return new BehaviorSubject<Identifier[]>([])

        let identifiers: Observable<Identifier>[] = response.map((element): Observable<Identifier> => {

          const mapper = new ApiRequestMapper<Identifier>(apiRequest);

          mapper.setMapperKey('name', element.name);
          mapper.setMapperKey('currentValue', element.currentValue);
          mapper.setMapperKey('increment', element.increment);

          return mapper.map();

        })

        return combineLatest(identifiers)
      }),
      mergeMap((identifiers: Identifier[]) => {

        foundIdentifier = identifiers.find(identifier => identifier.name === name)
        if (!foundIdentifier) return null

        foundIdentifier.currentValue = foundIdentifier.currentValue + foundIdentifier.increment

        return this.http.post(
          API + '/datastore/identifiers',
          identifiers,
          {
            headers: HEADERS
          }
        )
      }),
      map(() => {
        if (!foundIdentifier) return null
        return foundIdentifier
      })
    )
  }

}
