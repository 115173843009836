import { HttpResponse, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute, ApiMockRootRoute } from "@ng-stack/api-mock";

export class AccountApiMockRootRoute implements ApiMockRootRoute{

    static DB = {
        firstname: 'Mike',
        lastname: 'Viveros',
        mobile: '+441234567890',
        email: 'mikevive@gmail.com',
        postalcode: 'E1 6AN',
        country: 'GB',
        address: 'hay ray 901',
        dob: '1989-07-22',
        marketing: ["SMS", "POST"],
        disability: ["HEARING IMPAIRMENT"],
        roles: [
            {
                companyId: null,
                companyName: 'BASIS',
                role: 'trainee'
            },
            {
                companyId: null,
                companyName: 'BASIS',
                role: 'assessor'
            },
            {
                companyId: '10012345',
                companyName: 'Farming Online',
                role: 'nsd'
            },
            {
                companyId: '10012345',
                companyName: 'Farming Online',
                role: 'nsk'
            },
            {
                companyId: '10012345',
                companyName: 'Farming Online',
                role: 'employee'
            }
        ]
    }

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];
    userBD: any = null;

    constructor() {
        this.responseCallback = this.getResponseCallback();
    }


    private getResponseCallback() {
        console.log('HTTP api/account');
        return ({ reqBody, reqHeaders, httpMethod }) => {

            if (httpMethod == 'GET') {

                const { Authorization } = reqHeaders;
                let [scheme, encstr] = Authorization.split(' ');
                let [number, token] = window.atob(encstr).split(':');
                console.log(token);
                let user = null;

                if (token === 'ayJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlVzZXJOYW1lIiwiZmlyc3ROYW1lIjoiVXNlckZpc3ROYW1lIiwibGFzdE5hbWUiOiJVc2VyTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJuYW1lQGdtYWlsLmNvbSJ9.eiUupOFXIOkzlKiMiE2r1PBggAKdt9uAFZWO6Hp4uME') {

                    this.userBD = AccountApiMockRootRoute.DB;
                    user = this.userBD;

                    return new HttpResponse({
                        body: user,
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'user found',
                        status: 200,
                    });
                }
                else if (token === "byJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlVzZXJOYW1lIiwiZmlyc3ROYW1lIjoiVXNlckZpc3ROYW1lIiwibGFzdE5hbWUiOiJVc2VyTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJuYW1lQGdtYWlsLmNvbSJ9.eiUupOFXIOkzlKiMiE2r1PBggAKdt9uAFZWO6Hp4uME") {
                    console.log(this.userBD)
                    console.log(AccountApiMockRootRoute.DB)
                    if (this.userBD) {

                        user = this.userBD;

                        return new HttpResponse({
                            body: user,
                            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                            url: 'api/register',
                            statusText: 'user found',
                            status: 200,
                        });

                    } else {
                        return new HttpErrorResponse({
                            error: 'Unauthorised',
                            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                            url: 'api/register/confirm',
                            statusText: 'invalid header authentication',
                            status: 401,
                        });
                    }

                }
                else if (token === "byJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlVzZXJOYW1lIiwiZmlyc3ROYW1lIjoiVXNlckZpc3ROYW1lIiwibGFzdE5hbWUiOiJVc2VyTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJuYW1lQGdtYWlsLmNvbSJ9.eiUupOFXIOkzlKiMiE2r1PBggAKdt9uAFZWO6Hp4uME") {
                    return new HttpErrorResponse({
                        error: 'Unauthorised',
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register/confirm',
                        statusText: 'invalid header authentication',
                        status: 401,
                    });
                }
            }
            else if (httpMethod == 'POST') {

                reqBody.roles = [
                    {
                        companyId: null,
                        companyName: 'BASIS',
                        role: 'trainee'
                    }
                ]

                this.userBD = reqBody;

                return new HttpResponse({
                    body: { message: 'user created' },
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user found',
                    status: 200,
                });

            }
            else if (httpMethod == 'PUT') {
                for (const [key, value] of Object.entries(reqBody)) {
                    this.userBD[key] = value;
                }
                console.log('this.userBD')
                console.log(this.userBD)

                return new HttpResponse({
                    body: { message: 'user created' },
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user updated',
                    status: 200,
                });
            }
        }
    }
}