import { HttpClient } from "@angular/common/http";
import * as moment from "moment"
import { BehaviorSubject, Subject } from "rxjs";
import { API } from "src/environments/environment";
import { HEADERS } from "./constants";

export abstract class DataStoreService<T> {

  private CACHE_TIMEOUT_MINUTES = 30;

  private lastTimeCached = undefined;
  private datastoreCached: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private datastoreFetched: Subject<any> = new Subject<any>();

  private isFetching: Boolean = false;

  constructor(
    protected http: HttpClient,
    private name: string,
    private datastore: boolean = true
  ) { }

  fetchDataStore() {

    if(!this.isFetching){

      this.isFetching = true;

      const datastore: string = this.datastore? '/datastore/' : '/';

      this.http.get(
          API + datastore + this.name,
          {
              headers: HEADERS
          }
      ).subscribe((response: any) => {
        this.setDataStore(response)
      })
    }

    return this.datastoreFetched.asObservable();

  }

  getDataStore() {
    if(this.lastTimeCached){
      let duration = moment.duration(moment().diff(this.lastTimeCached)).minutes();
      if(duration < this.CACHE_TIMEOUT_MINUTES){
        return this.datastoreCached.asObservable();
      }
    }
    return this.fetchDataStore();
  }

  setDataStore(data) {
    this.lastTimeCached = moment();
    this.datastoreFetched.next(data)
    this.datastoreCached.next(data);
    this.isFetching = false;
  }

}
