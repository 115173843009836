import { Injectable } from '@angular/core';
import { ApiMockRootRoute, ApiMockService } from '@ng-stack/api-mock';
import { IS_BACKEND_MIXED } from 'src/environments/environment';
import { MockRoute } from './mockRoutes/mock-route';


@Injectable({
    providedIn: 'root'
})
export class ApiMockBackendService implements ApiMockService {

    getRoutes(): ApiMockRootRoute[] {
        if (IS_BACKEND_MIXED) {
            return MockRoute.values()
                .filter((mockRoute: MockRoute) => !mockRoute.isImplemented())
                .map(mockRoute => mockRoute.getApiRoute())
        } else {
            return MockRoute.values().map(mockRoute => mockRoute.getApiRoute());
        }
    }
}