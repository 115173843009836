import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ApiRequest<T> {

    private structure: T;

    constructor(private apiRequest: string) {

        let string = this.apiRequest
        .replace(/\s+/g, '')
        .replace(/([^}])(,)/g, '$1":true,')
        .replace(/([^},])(})/g, '$1":true}')
        .replace(/,}/g, '}')
        .replace(/,/g, ',"')
        .replace(/(.)({)/g, '$1":{')
        .replace(/{/g, '{"')
        
        this.structure = JSON.parse(string);

    }

    getStructure(): T {
        return this.structure;
    }

    getStructureChild<K extends keyof T>(key: K): ApiRequest<any>{

        if(!this.structure[key]) return null;

        let apiRequestString = JSON.stringify(this.structure[key])
            .replace(/"|:|true/g,'');

        let apiRequestChild = new ApiRequest<any>(apiRequestString);
        return apiRequestChild;
    }

}

export class ApiRequestMapper<T>{

    private mapper: any = {};

    private structure: T;

    constructor(apiRequest: ApiRequest<T>){
        this.structure = apiRequest.getStructure();
     }

    setMapperKey<K extends keyof T, V extends T[K]>(key: K, value: V | Observable<V>): void{
        this.mapper[key] = this.structure[key] ? value : undefined;
    }

    map(): Observable<T> {

        let response: any = {};

        let observables = [];
        let childrens: string[] = [];

        Object.keys(this.mapper).forEach(key => {

            if (!this.mapper[key]) {
                delete this.mapper[key]
                return
            }

            if (typeof this.structure[key] === 'object') {
                if(typeof this.mapper[key] === 'function'){
                    let observable = this.mapper[key]();
                    observables.push(observable);
                    childrens.push(key);
                }
                else{
                    console.log(`Mapping ${key} in ApiRequest requires a function, but an ${typeof this.mapper[key]} was passed ${this.mapper[key]}`)
                }
            }
            else {
                response[key] = this.mapper[key];
            }
        });

        if (observables.length > 0) {
            return combineLatest(observables).pipe(
                map((results: any) => {
                    results.forEach((result, index) => {
                        response[childrens[index]] = result;
                    })
                    return response;
                })
            );
        }
        else {
            return new BehaviorSubject<T>(response).asObservable();
        }
    }

}
