import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/provider';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const autoLogoutMessages = {
          loginAnotherDevice:
            'You have been logged out because you have logged in on another device. If this was not you, please secure your account by resetting your password using the forgotten password feature.',
          sessionExpired: 'Your session has expired.',
        };

        if (err.status === 401) {
          if (this.authenticationService.getToken()) {
            // only auto logout if 401 response returned from api and in 2 case login another device/session expired
            this.authenticationService.logout();
            let errorResponse: string = err?.error?.error?.message ?? '';

            if (errorResponse?.includes('Error verifying token:')) {
              if (errorResponse?.includes('token-is-invalid')) {
                this.router.navigate(['login'], {
                  state: {
                    errorMessage: autoLogoutMessages.loginAnotherDevice,
                  },
                });
              } else {
                this.router.navigate(['login'], {
                  state: { errorMessage: autoLogoutMessages.sessionExpired },
                });
              }
            }
          }
        }

        return throwError(err);
      })
    );
  }
}
