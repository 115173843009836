import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock";

export class AuditorApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            id: 1,
            identity: "mikevive@gmail.com",
            token: "123456",
            firstname: 'Mike',
            lastname: 'Viveros',
            phone: '+441234567890',
            email: 'mikevive@gmail.com',
        },
        {
            id: 2,
            identity: "jose@gmail.com",
            token: "123456",
            firstname: 'jose',
            lastname: 'Viveros',
            phone: '+441234567890',
            email: 'jose@gmail.com',
        },
        {
            id: 3,
            identity: "juan@gmail.com",
            token: "123456",
            firstname: 'Juan',
            lastname: 'Viveros',
            phone: '+441234567890',
            email: 'juan@gmail.com',
        },
        {
            id: 4,
            identity: "felipe@gmail.com",
            token: "123456",
            firstname: 'felipe',
            lastname: 'Viveros',
            phone: '+441234567890',
            email: 'felipe@gmail.com',
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return AuditorApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
        }
    }
}