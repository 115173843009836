import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { API, HEADERS } from './constants';
import { MigrationData } from '../interfaces/provider';


@Injectable({
  providedIn: 'root'
})
export class MigrationService {

  private migrationData: BehaviorSubject<MigrationData> = new BehaviorSubject<MigrationData>({oldbasisid:null,firstName:"",lastName:"",postalcode:"", address: "", dob: ""});
  private isMigrationPassed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  setIsMigrationPassed(isMigrationPassed: boolean ){
    this.isMigrationPassed.next(isMigrationPassed);
  }
 
  getIsMigrationPassed(): Observable<boolean>{
    return this.isMigrationPassed.asObservable();
  }

  getIsMigrationPassedValue(): boolean{
    return this.isMigrationPassed.getValue();
  }

  setMigrationData(migrationData: MigrationData ){
    this.migrationData.next(migrationData);
  }
 
  getMigrationData(): Observable<MigrationData>{
    return this.migrationData.asObservable();
  }

  requestMigrationData(id: string, password: string): Observable<any>{
    return this.http.post(
      API + '/migrate/person',
      {
        "basisid": id,
        "password": password
      },
      {
        headers: HEADERS,
        observe: 'response'
      }
    );
  }

}
