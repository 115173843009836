import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/provider';

@Injectable({
  providedIn: 'root'
})
export class IsUserLoginGuard implements CanActivate {

  constructor(private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authenticationService.getToken();

    if(token){
      return false;
    }
    return true;
  }

}
