import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private breakPoint992px: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      '(max-width: 992px)'
    ]).subscribe(result => {
        this.breakPoint992px.next(result.matches);
    });
  }

  getbreakPoint992px(): Observable<boolean>{
    return this.breakPoint992px;
  } 
}
