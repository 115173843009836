import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/provider';
import { BE_V1_ID, BE_V1_TOKEN } from 'src/environments/environment';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  token: string;
  id: string;

  constructor(private authenticationService: AuthenticationService) {
    //this.authenticationService.getToken().subscribe(token => { this.token = token });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    this.token = this.authenticationService.getToken();
    this.id = this.authenticationService.getId();

    if (this.token !== null) {
      // INFO: Detect which token should be used for authentication (BE v1 or BE v2)
      let authorizeToken = 'Bearer ' + this.token;
      if (!request.url.includes('bev2')) {
        authorizeToken = 'Basic ' + window.btoa(BE_V1_ID + ':' + BE_V1_TOKEN);
      }

      request = request.clone({
        setHeaders: {
          Authorization: authorizeToken,
        },
      });
    }

    return next.handle(request);
  }
}
