import { Component, Input, OnInit } from '@angular/core';
import { ComponentItem } from 'src/app/interfaces/provider';
import { ActionButtonComponent } from '../../atoms/action-button/action-button.component';
import { DashboardItemTitleLabelComponent } from '../../atoms/labels/molecules/dashboard-item/dashboard-item-title-label/dashboard-item-title-label.component';
import { IconsComponent } from '../../particles/icons/icons.component';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.css']
})
export class DashboardItemComponent implements OnInit {

  @Input() data: any;

  icon: ComponentItem;
  title: ComponentItem;
  component: ComponentItem;

  constructor() { }

  ngOnInit(): void {

    this.icon = {
      component: IconsComponent,
      data: {
        color:'grey-900',
        icon: this.data.icon
      }
    }
  
    this.title = {
      component: DashboardItemTitleLabelComponent,
      data: {
        label: this.data.label
      }
    }

    var componentType;
    
    if (!this.data.type || this.data.type === "ActionButton" ){
      componentType = ActionButtonComponent
    }

    this.component = {
      component: componentType,
      data: this.data.componentData
    }

  }
  

}
