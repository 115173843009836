<button *ngIf="data.typeB !== 'download'" class="t1-14-bold text-grey-900 secondary-400"
id="actionButton-{{buttonData.label}}"
type = {{buttonData.typeB}}
(click)="click()"
[ngClass]="{'primary' : buttonData.type==='primary',
'primary-dark' : buttonData.type==='primary-dark',
'primary--disable' : buttonData.type==='primary' && buttonData.enable===false,
'secondary' : buttonData.type==='secondary',
'secondary-dark' : buttonData.type==='secondary-dark',
'secondary--disable' : buttonData.type==='secondary' && buttonData.enable===false}"
[attr.disabled]="disable()">{{buttonData.label}}</button>

<a *ngIf="data.typeB === 'download'" class="t1-14-bold text-grey-900 secondary-400"
id="actionButton-{{buttonData.label}}"
(click)="click()"
[href]="buttonData.href"
download="{{buttonData.download}}"
target="_blank"
[ngClass]="{'primary' : buttonData.type==='primary',
'secondary' : buttonData.type==='secondary'}"
[attr.disabled]="disable()">{{buttonData.label}}</a>
