import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, mergeMap, tap } from 'rxjs/operators';
import { API, HEADERS } from 'src/app/services/constants';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { Section } from 'src/app/models/store/section';
import { Question } from 'src/app/models/store/question';
import { StoreQuestionService } from './store-question.service';
import { Identifier } from 'src/app/models/store/identifier';
import { StoreIdentifierService } from './store-identifier.service';
import { DataStoreService } from '../datastore.service';

@Injectable({
    providedIn: 'root'
})
export class StoreSectionService extends DataStoreService<Section> {

    constructor(
        protected http: HttpClient,
        private storeQuestionService: StoreQuestionService,
        private storeIdentifierService: StoreIdentifierService
    ) {
        super(http, 'sections')
    }

    getAll(apiRequest: ApiRequest<Section>): Observable<Section[]> {

        console.log('>> section get all')
        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Section[]> => {

                if(!response) return new BehaviorSubject<Section[]>([])

                let inspections: Observable<Section>[] = response.map((element): Observable<Section> => {

                    const mapper = new ApiRequestMapper<Section>(apiRequest);

                    mapper.setMapperKey('id', element.id);
                    mapper.setMapperKey('title', element.title);

                    let questionApiRequest = apiRequest.getStructureChild('questions')

                    if(questionApiRequest){
                        let questions: Observable<Section>[] = element.questions.map((question): Observable<Question> => {
                            return this.storeQuestionService.getById(
                                question,
                                questionApiRequest
                            )
                        })
                        let combineQuestions = () => combineLatest(questions)
                        mapper.setMapperKey('questions', questions.length > 0? combineQuestions.bind(this):[])
                    }

                    return mapper.map()

                })

                return combineLatest(inspections)
            })
        )

    }

    getById(id: number, apiRequest: ApiRequest<Section>): Observable<Section> {

        console.log('>> section by id: ', id)
        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Section> => {

                console.log('response 🔥')
                console.log(response)

                if(!response)  return new BehaviorSubject<Section>(null)

                let element = response.find(section => section.id == id)

                const mapper = new ApiRequestMapper<Section>(apiRequest);

                mapper.setMapperKey('id', element.id);
                mapper.setMapperKey('title', element.title);

                let questionApiRequest = apiRequest.getStructureChild('questions')

                if(questionApiRequest){
                    let questions: Observable<Section>[] = element.questions.map((question): Observable<Question> => {
                        return this.storeQuestionService.getById(
                            question,
                            questionApiRequest
                        )
                    })
                    let combineQuestions = () => combineLatest(questions)
                    mapper.setMapperKey('questions', questions.length > 0? combineQuestions.bind(this):[])
                }

                return mapper.map()

            })
        )
    }


    create(section: Section){

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<any> => {
                return this.storeIdentifierService.increaseAndGetByName('sections').pipe(
                    mergeMap((identifier: Identifier) => {

                        let sectionData = {
                            id: identifier.currentValue,
                            questions: section.questions.map(question => question.id),
                            title: section.title,
                        }
                        response.push(sectionData)

                        return this.http.post (
                            API + '/datastore/sections',
                            response,
                            {
                                headers: HEADERS
                            }
                        )
                    }),
                    tap(() => {
                        this.setDataStore(response);
                    })
                )
            })
        )
    }

    deleteById(id: number){

        return this.getDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let datastoreFiltered = datastore.filter(section =>  section.id != id)
                return this.http.post (
                    API + '/datastore/sections',
                    datastoreFiltered,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastoreFiltered);
                    })
                )
            })
        )

    }

    updateById(id: number, section: Section){

        return this.getDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let sectionData  = datastore.find(section => section.id == id)

                sectionData.questions = section.questions.map(question => question.id)
                sectionData.title = section.title

                return this.http.post (
                    API + '/datastore/sections',
                    datastore,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastore);
                    })
                )
            })
        )
    }

}
