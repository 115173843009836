import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockRootRoute, ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute } from "@ng-stack/api-mock";

export class CategoryApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            "id": 1,
            "title": "Legislative"
        },
        {
            "id": 2,
            "title": "Serious"
        },
        {
            "id": 3,
            "title": "Good Practice"
        },
        {
            "id": 4,
            "title": "Information"
        },
        {
            "id": 5,
            "title": "Awesome"
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return CategoryApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
        }
    }
}
