import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, mergeMap, tap } from 'rxjs/operators';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { Category } from 'src/app/models/store/category';
import { Identifier } from 'src/app/models/store/identifier';
import { API } from 'src/environments/environment';
import { HEADERS } from '../constants';
import { DataStoreService } from '../datastore.service';
import { StoreIdentifierService } from './store-identifier.service';

@Injectable({
    providedIn: 'root'
})
export class StoreCategoryService extends DataStoreService<Category> {

    constructor(
        protected http: HttpClient,
        private storeIdentifierService: StoreIdentifierService
    ) {
        super(http, 'categories')
    }

    getAll(apiRequest: ApiRequest<Category>): Observable<Category[]> {

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Category[]> => {

                if(!response) return new BehaviorSubject<Category[]>([])

                let inspections: Observable<Category>[] = response.map((element): Observable<Category> => {

                    const mapper = new ApiRequestMapper<Category>(apiRequest);

                    mapper.setMapperKey('id', element.id);
                    mapper.setMapperKey('title', element.title);

                    return mapper.map();

                })

                return combineLatest(inspections)
            })
        )

    }

    getById(id: number, apiRequest: ApiRequest<Category>): Observable<Category> {

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Category> => {

                let element = response.find(category => category.id == id)

                if (!element) return new BehaviorSubject<Category>(null)

                const mapper = new ApiRequestMapper<Category>(apiRequest);

                mapper.setMapperKey('id', element.id);
                mapper.setMapperKey('title', element.title);

                return mapper.map();

            })
        )
    }

    create(category: Category) {

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<any> => {
                return this.storeIdentifierService.increaseAndGetByName('categories').pipe(
                    mergeMap((identifier: Identifier) => {

                        let schemeData = {
                            id: identifier.currentValue,
                            title: category.title,
                        }

                        response.push(schemeData)

                        return this.http.post(
                            API + '/datastore/categories',
                            response,
                            {
                                headers: HEADERS
                            }
                        )
                    }),
                    tap(() => {
                        this.setDataStore(response);
                    })
                )
            }))
    }

    deleteById(id: number) {

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {

                let datastoreFiltered = datastore.filter(category => category.id != id)

                if (datastoreFiltered.length === 0) {
                    datastoreFiltered = JSON.stringify([])
                }

                return this.http.post(
                    API + '/datastore/categories',
                    datastoreFiltered,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastoreFiltered);
                    })
                )
            })
        )

    }

    updateById(id: number, category: Category) {

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let schemeData = datastore.find(category => category.id == id)
                schemeData.title = category.title

                return this.http.post(
                    API + '/datastore/categories',
                    datastore,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastore);
                    })
                )
            })
        )


    }

}
