import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, catchError, first } from 'rxjs/operators';
import { API, HEADERS } from 'src/app/services/constants';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { Scheme } from 'src/app/models/store/scheme';
import { Membership } from 'src/app/models/store/membership';
import { StoreMembershipService } from './store-membership.service';
import { DataStoreService } from '../datastore.service';

@Injectable({
    providedIn: 'root'
})
export class StoreSchemeService extends DataStoreService<Scheme> {

    constructor(
        protected http: HttpClient,
        private storeMembershipService: StoreMembershipService
    ) {
        super(http, 'scheme', false)
    }

    getAll(apiRequest: ApiRequest<Scheme>): Observable<Scheme[]> {
        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Scheme[]> => {

                if(!response) return new BehaviorSubject<Scheme[]>([])

                let scheme: Observable<Scheme>[] = response.map((element): Observable<Scheme> => {

                    const mapper = new ApiRequestMapper<Scheme>(apiRequest)

                    mapper.setMapperKey('id', element.id);
                    mapper.setMapperKey('title', element.attrs.name);

                    let membershipsApiRequest = apiRequest.getStructureChild('memberships');

                    if(membershipsApiRequest){
                        let memberships: Observable<Membership>[] = element.membership_ids.map((membership_id): Observable<Membership> => {
                            return this.storeMembershipService.getById(
                                membership_id,
                                membershipsApiRequest
                            )
                        })
                        let combineSections = () => combineLatest(memberships)
                        mapper.setMapperKey('memberships', memberships.length > 0? combineSections.bind(this):[]);
                    }

                    return mapper.map();

                })
                console.log('scheme')
                return combineLatest(scheme);
            })
        )
    }

    getById(id: number, apiRequest: ApiRequest<Scheme>): Observable<Scheme> {

        if(!id) return new BehaviorSubject<Scheme>(null).asObservable()

        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Scheme> => {

                if(!response) return new BehaviorSubject<Scheme>(null)

                let element = response.find(scheme =>  scheme.id == id)

                if(!element) return new BehaviorSubject<Scheme>(null)

                const mapper = new ApiRequestMapper<Scheme>(apiRequest)

                mapper.setMapperKey('id', element.id);
                mapper.setMapperKey('title', element.attrs.name);

                let membershipsApiRequest = apiRequest.getStructureChild('memberships');

                if(membershipsApiRequest){
                    let memberships: Observable<Membership>[] = element.membership_ids.map((membership_id): Observable<Membership> => {
                        return this.storeMembershipService.getById(
                            membership_id,
                            membershipsApiRequest
                        )
                    })
                    let combineSections = () => combineLatest(memberships)
                    mapper.setMapperKey('memberships', memberships.length > 0? combineSections.bind(this):[]);
                }

                return mapper.map();

            }),
            catchError(this.handleError<Scheme>(`get scheme id=${id}`))
        )
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log(operation)
            console.error(error);
            return of(result as T);
        };
    }

}
