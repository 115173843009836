import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock";

export class InspectionApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            inspection_id: 1,
            activity: {
                address: "act address",
                latitude: 1.1,
                longitude: 1.1,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 1,
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 1,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            member_id: 1,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 1,
            response: {
                "inspection" : [
                  {
                    "answer" : "18:23",
                    "category_id" : 4,
                    "question" : "What time did you arrive for the Assessment",
                    "question_id" : 3
                  },
                  {
                    "answer" : "4",
                    "category_id" : 2,
                    "question" : "Is there a Nominated Storekeeper available at the site",
                    "question_id" : 5
                  },
                  {
                    "answer" : "Arthur Brown",
                    "category_id" : 4,
                    "question" : "Who is the Senior Nominated Storekeeper",
                    "question_id" : 6
                  },
                  {
                    "answer" : "01923 445566",
                    "category_id" : 4,
                    "question" : "What is their contact telephone number at the premise",
                    "question_id" : 7
                  },
                  {
                    "answer" : "Admin@email.com",
                    "category_id" : 4,
                    "question" : "What is their email address at the premise",
                    "question_id" : 8
                  },
                  {
                    "answer" : "Jane Smith",
                    "category_id" : 4,
                    "question" : "Who was the assessment conducted with",
                    "question_id" : 9
                  },
                  {
                    "answers" : [
                      {
                        "answer" : "Change of NSK",
                        "failscore" : 30,
                        "subquestions" : [
                          {
                            "answer" : "NSK Changed",
                            "category_id" : 4,
                            "question" : "Provide details",
                            "question_id" : 2
                          }
                        ]
                      },
                      {
                        "answer" : "Change of premise email",
                        "failscore" : 30,
                        "subquestions" : [
                          {
                            "answer" : "Admin2@email.com",
                            "category_id" : 4,
                            "question" : "Provide details",
                            "question_id" : 2
                          }
                        ]
                      },
                      {
                        "answer" : "Change of other information (record as observation)",
                        "failscore" : 30,
                        "subquestions" : [
                          {
                            "answer" : "Other info ",
                            "category_id" : 4,
                            "question" : "Provide details",
                            "question_id" : 2
                          }
                        ]
                      }
                    ],
                    "category_id" : 4,
                    "observation" : "Observation of the question\n\nIs entered here",
                    "question" : "Has the above information changed since the last audit",
                    "question_id" : 10
                  },
                  {
                    "answers" : [
                      {
                        "answer" : "Other (Record as an observation)",
                        "failscore" : 20,
                        "subquestions" : [
                          {
                            "answer" : "Others details provided",
                            "category_id" : 4,
                            "question" : "Provide details",
                            "question_id" : 2
                          }
                        ]
                      }
                    ],
                    "category_id" : 1,
                    "question" : "Is the Storekeeper aware of who to contact should product advice be required (BASIS Certificate in Crop Protection holder)",
                    "question_id" : 11
                  },
                   {
                    "answer" : "2021-06-09",
                    "category_id" : 2,
                    "failscore" : 0,
                    "question" : "Is the store approved or acknowledged by the Environment Agency",
                    "question_id" : 12
                  },
                  {
                    "answer" : "No",
                    "category_id" : 2,
                    "failscore" : 0,
                    "question" : "Is there an audible means of raising the alarm in the event of a fire",
                    "question_id" : 25
                  },
                  {
                    "answer" : "N/A",
                    "category_id" : 4,
                    "failscore" : 20,
                    "question" : "Are there smoke/fire detectors in the store",
                    "question_id" : 26
                  },
                  {
                    "answer" : "Yes",
                    "category_id" : 2,
                    "failscore" : 10,
                    "question" : "Do fire exit signs in the store comply with current Regulations",
                    "question_id" : 27
                  },
                  {
                    "answers" : [
                      {
                        "answer" : "Other",
                        "failscore" : 20,
                        "subquestions" : [
                          {
                            "answer" : "Others fire procedure",
                            "category_id" : 4,
                            "question" : "Provide details",
                            "question_id" : 2
                          }
                        ]
                      }
                    ],
                    "category_id" : 2,
                    "question" : "Is the fire procedure displayed",
                    "question_id" : 29
                  },
                  {
                    "answer" : "Yes (-)",
                    "category_id" : 3,
                    "failscore" : 0,
                    "question" : "Have you any concerns regarding the fire arrangements at this site",
                    "question_id" : 32
                  }
                ],
                "inspection_id" : 2,
                "scheme_id" : [
                  2,
                  1,
                  3,
                  4
                ]
              },
            scheme_id: [
                1
            ]
        },
        {
            inspection_id: 2,
            activity: {
                address: "act address",
                latitude: 1.1,
                longitude: 1.1,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 1,
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 3,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            member_id: 1,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 1,
            response: { },
            scheme_id: [
                1
            ]
        },
        {
            inspection_id: 3,
            activity: {
                address: "act address",
                latitude: 2.2,
                longitude: 2.2,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 2,
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 4,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            member_id: 2,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 2,
            response: {
                audit_id: 4,
                inspection: [
                    {
                        answer: "No",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 0,
                        question: "The Question",
                        question_id: 1
                    },
                    {
                        action: {
                            deadline: "2021-05-20T16:55:31+01:00",
                            reason: "reason action required",
                            todo: "the action to take"
                        },
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        observation: "any observations recorded by auditor",
                        question: "Is a '?' needed on the end?",
                        question_id: 3,
                        subquestions: [
                            {
                                answer: ["reason for yes", "reason for no"],
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Provide details",
                                question_id: 2
                            }
                        ]
                    }
                ],
                inspection_id: 2,
                scheme_id: [
                    2
                ]
            },
            scheme_id: [
                2
            ]
        },
        {
            inspection_id: 4,
            activity: {
                address: "act address",
                latitude: 1.1,
                longitude: 1.1,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 1,
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 5,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            member_id: 1,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 1,
            response: {
                audit_id: 5,
                inspection: [
                    {
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        photos: [
                            {
                                data: "data:image/jpeg;base64,aaaaaaaaaaa.....",
                                info: "info about image"
                            }
                        ],
                        question: "The Question",
                        question_id: 1,
                        subquestions: [
                            {
                                answer: "reason for yes",
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Why Yes",
                                question_id: 2
                            }
                        ]
                    }
                ],
                scheme_id: [
                    1
                ]
            },
            scheme_id: [
                1
            ]
        },
        {
            inspection_id: 5,
            activity: {
                address: "act address",
                latitude: 1.1,
                longitude: 1.1,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 1,
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 7,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            member_id: 1,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 1,
            response: {
                audit_id: 7,
                inspection: [
                    {
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        photos: [
                            {
                                data: "data:image/jpeg;base64,aaaaaaaaaaa.....",
                                info: "info about image"
                            }
                        ],
                        question: "The Question",
                        question_id: 1,
                        subquestions: [
                            {
                                answer: "reason for yes",
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Why Yes",
                                question_id: 2
                            }
                        ]
                    }
                ],
                scheme_id: [
                    1
                ]
            },
            scheme_id: [
                1
            ]
        },
        {
            inspection_id: 6,
            activity: {
                address: "act address",
                latitude: 2.2,
                longitude: 2.2,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 2,
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 8,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            member_id: 2,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 2,
            response: {
                audit_id: 8,
                inspection: [
                    {
                        answer: "No",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 0,
                        question: "The Question",
                        question_id: 1
                    },
                    {
                        action: {
                            deadline: "2021-05-20T16:55:31+01:00",
                            reason: "reason action required",
                            todo: "the action to take"
                        },
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        observation: "any observations recorded by auditor",
                        question: "Is a '?' needed on the end?",
                        question_id: 3,
                        subquestions: [
                            {
                                answer: ["reason for yes", "reason for no"],
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Provide details",
                                question_id: 2
                            }
                        ]
                    }
                ],
                inspection_id: 2,
                scheme_id: [
                    2
                ]
            },
            scheme_id: [
                2
            ]
        },
        {
            inspection_id: 7,
            activity: {
                address: "act address",
                latitude: 2.2,
                longitude: 2.2,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 2,
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 10,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            member_id: 2,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 2,
            response: {
                audit_id: 10,
                inspection: [
                    {
                        answer: "No",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 0,
                        question: "The Question",
                        question_id: 1
                    },
                    {
                        action: {
                            deadline: "2021-05-20T16:55:31+01:00",
                            reason: "reason action required",
                            todo: "the action to take"
                        },
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        observation: "any observations recorded by auditor",
                        question: "Is a '?' needed on the end?",
                        question_id: 3,
                        subquestions: [
                            {
                                answer: ["reason for yes", "reason for no"],
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Provide details",
                                question_id: 2
                            }
                        ]
                    }
                ],
                inspection_id: 2,
                scheme_id: [
                    2
                ]
            },
            scheme_id: [
                2
            ]
        },
        {
            inspection_id: 8,
            activity: {
                address: "act address",
                latitude: 1.1,
                longitude: 1.1,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 1,
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 11,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            member_id: 1,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 1,
            response: {
                audit_id: 11,
                inspection: [
                    {
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        photos: [
                            {
                                data: "data:image/jpeg;base64,aaaaaaaaaaa.....",
                                info: "info about image"
                            }
                        ],
                        question: "The Question",
                        question_id: 1,
                        subquestions: [
                            {
                                answer: "reason for yes",
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Why Yes",
                                question_id: 2
                            }
                        ]
                    }
                ],
                scheme_id: [
                    1
                ]
            },
            scheme_id: [
                1
            ]
        },
        {
            inspection_id: 9,
            activity: {
                address: "act address",
                latitude: 2.2,
                longitude: 2.2,
                name: "act name",
                postalcode: "act postalcode"
            },
            activity_id: 2,
            auditor: {
                email: "auditor email",
                firstname: "auditor firstname",
                lastname: "auditor lastname",
                phone: "auditor phone"
            },
            auditor_id: 20,
            booked: "YYYY-MM-DDTHH:MM:SS+Z",
            id: 12,
            member: {
                email: "member email",
                firstname: "member firstname",
                lastname: "member lastname",
                phone: "member phone"
            },
            member_id: 2,
            organisation: {
                address: "org address",
                email: "org email",
                name: "org name",
                phone: "org phone",
                postalcode: "org postalcode"
            },
            organisation_id: 2,
            response: {
                audit_id: 12,
                inspection: [
                    {
                        answer: "No",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 0,
                        question: "The Question",
                        question_id: 1
                    },
                    {
                        action: {
                            deadline: "2021-05-20T16:55:31+01:00",
                            reason: "reason action required",
                            todo: "the action to take"
                        },
                        answer: "Yes",
                        status: 'PASS',
                        category_id: 1,
                        category: 'SERIOUS',
                        failscore: 1,
                        observation: "any observations recorded by auditor",
                        question: "Is a '?' needed on the end?",
                        question_id: 3,
                        subquestions: [
                            {
                                answer: ["reason for yes", "reason for no"],
                                status: 'FAIL',
                                category_id: 4,
                                category: 'LEGISLATIVE',
                                question: "Provide details",
                                question_id: 2
                            }
                        ]
                    }
                ],
                inspection_id: 2,
                scheme_id: [
                    2
                ]
            },
            scheme_id: [
                2
            ]
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return InspectionApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
            else if (httpMethod == 'POST') {
                console.log("entro post")
            }
        }
    }
}
