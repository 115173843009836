import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  observable,
  Observable,
  of,
} from 'rxjs';
import { API, HEADERS } from './constants';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Activity } from '../models/store/activity';
import { Personnel } from '../models/personnel';
import { Organisation } from '../models/store/organisation';
import { AuthenticationService } from './authentication.service';
import { Assignment, Inspection } from '../models/store/inspection';
import { User } from '../models/user';
import { StoreMembershipService } from './store/store-membership.service';
import { Membership } from '../models/store/membership';
import { ApiRequest } from '../models/apiRequest';
import { Scheme } from '../models/store/scheme';
import { StoreSchemeService } from './store/store-scheme.service';

@Injectable({
  providedIn: 'root',
})
export class NsdService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private storeMembershipService: StoreMembershipService,
    private storeSchemeService: StoreSchemeService
  ) {}

  // Organisation

  getAllOrganisations(): Observable<any> {
    return this.http
      .get<Array<Organisation>>(API + '/organisation', {
        headers: HEADERS,
        observe: 'response',
      })
      .pipe(
        map(response => {
          if (response.status === 204) return null;
          return response.body;
        })
      );
  }

  getOrganisationById(organisationId: number): Observable<any> {
    return this.http
      .get<Array<Organisation>>(API + '/organisation/' + organisationId, {
        headers: HEADERS,
        observe: 'response',
      })
      .pipe(
        map(response => {
          if (response.status === 204) return null;
          return response.body;
        })
      );
  }

  requestOrganisationMember(memberId: number): Observable<any> {
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http.post(
          API + '/member/invite',
          {
            identity: memberId,
            org_id: role.companyId,
          },
          {
            headers: HEADERS,
            observe: 'response',
          }
        );
      })
    );
  }

  getAllOrganisationRequestedMembers(): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.get(
      `${API}/organisation/organisations/${companyId}/invitations`,
      {
        headers: HEADERS,
      }
    );
  }

  getAllOrganisationMembers(): Observable<any> {
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http
          .get<Array<Personnel>>(
            API + '/organisation/' + role.companyId + '/members',
            {
              headers: HEADERS,
              observe: 'response',
            }
          )
          .pipe(
            map(response => {
              if (response.status === 204) return null;
              return response.body;
            })
          );
      })
    );
  }

  updateOrganisationMember() {}

  removeOrganisationMember(memberId: number): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.delete<Array<Personnel>>(
      `${API}/organisation/${companyId}/member/${memberId}`,
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  addOrganisationRoleToMember(
    memberId: number,
    role: string
  ): Observable<void> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.put<void>(
      `${API}/organisation/add-role`,
      {
        account_id: memberId,
        organisation_id: companyId,
        role: role,
      },
      {
        headers: HEADERS,
      }
    );
  }

  removeOrganisationRoleFromMember(
    memberId: number,
    role: string
  ): Observable<void> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.put<void>(
      `${API}/organisation/delete-role`,
      {
        account_id: memberId,
        organisation_id: companyId,
        role: role,
      },
      {
        headers: HEADERS,
      }
    );
  }

  // Activity

  addOrganisationActivity(body): Observable<any> {
    return this.http.post(API + '/organisation/activity', body, {
      headers: HEADERS,
      observe: 'response',
    });
  }

  getAllOrganisationActivities(): Observable<any> {
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http
          .get<Array<Activity>>(
            API + '/organisation/' + role.companyId + '/activities',
            {
              headers: HEADERS,
              observe: 'response',
            }
          )
          .pipe(
            map(response => {
              if (response.status === 204) return [];
              return response.body;
            })
          );
      })
    );
  }

  getOrganisationActivity(activity_id: number): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http
      .get<Array<Activity>>(
        API + '/organisation/' + companyId + '/activity/' + activity_id,
        {
          headers: HEADERS,
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          if (response.status === 204) return null;
          return response.body;
        })
      );
  }

  getAllOrganisationPlaces(): Observable<any> {
    // return new BehaviorSubject<any>([
    //   {
    //     id: 1,
    //     attrs:{name: 'test I'}
    //   },
    //   {
    //     id: 2,
    //     attrs:{name: 'test II'}
    //   },
    //   {
    //     id: 3,
    //     attrs:{name: 'test III'}
    //   }
    // ])
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http
          .get<Array<Activity>>(
            API + '/organisation/' + role.companyId + '/places',
            {
              headers: HEADERS,
              observe: 'response',
            }
          )
          .pipe(
            map(response => {
              if (response.status === 204) return [];
              return response.body;
            })
          );
      })
    );
  }

  createPlace(place: any): Observable<any> {
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http.post<Array<Activity>>(
          API + '/organisation/' + role.companyId + '/places',
          place,
          {
            headers: HEADERS,
            observe: 'response',
          }
        );
      })
    );
  }

  requestUpdateOrganisationActivity(
    activityId: number,
    body: any
  ): Observable<any> {
    const companyId: number =
      this.authenticationService.getActiveRoleValue().companyId;
    body.organisationId = companyId;
    return this.http.post<Array<Activity>>(
      API + '/request',
      {
        entity: 'ACTIVITY',
        entity_id: activityId,
        method: 'UPDATE',
        attrs: body,
      },
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  addActivityMember(activityId, memberId, role): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.post(
      `${API}/organisation/${companyId}/activity/${activityId}/member/${memberId}`,
      {
        roles: [role],
      },
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  getAllActivityMembers(activity_id: number): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http
      .get<Array<Personnel>>(
        `${API}/organisation/${companyId}/activity/${activity_id}/members`,
        {
          headers: HEADERS,
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          if (response.status === 204) return [];
          return response.body;
        })
      );
  }

  updateActivityMember(activityId, memberId, role): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.put(
      `${API}/organisation/${companyId}/activity/${activityId}/member/${memberId}`,
      {
        roles: [role],
      },
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  removeActivityMember(activityId, memberId): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.delete(
      `${API}/organisation/${companyId}/activity/${activityId}/member/${memberId}`,
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  deleteOrganisationActivity(activitylId: any): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http.delete<Array<Activity>>(
      `${API}/organisation/${companyId}/activity/${activitylId}`,
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  // Members

  getMemberById(memberId: number): Observable<any> {
    const companyId = this.authenticationService.getActiveRoleValue().companyId;
    return this.http
      .get<any>(API + '/account/organisation-accounts/' + memberId, {
        headers: HEADERS,
      })
      .pipe(
        map(response => {
          if (!response) return null;

          return {
            id: response.id,
            firstname: response.firstname,
            lastname: response.lastname,
            email: response.email,
            mobile: response.mobile,
            organisation: response.organisations.find(
              organisation => organisation.id === companyId
            ),
          };
        })
      );
  }

  getMemberActivities(memberId: number): Observable<any> {
    return this.getAllOrganisationActivities().pipe(
      mergeMap(allOrganisationActivities => {
        if (allOrganisationActivities.length === 0) return of([]);

        const observables: Observable<any>[] = allOrganisationActivities.map(
          activity => {
            return this.getAllActivityMembers(activity.id).pipe(
              map(members => {
                if (members.length === 0) return null;
                const foundMember = members.find(
                  member => member.account.id === memberId
                );
                if (foundMember) {
                  activity.roles = foundMember.roles;
                  return activity;
                }
                return null;
              })
            );
          }
        );

        return combineLatest(observables).pipe(
          map(responses => {
            return responses.reduce(
              (memberOrganisationActivities, activity) => {
                if (activity)
                  return memberOrganisationActivities.concat(activity);
                return memberOrganisationActivities;
              },
              []
            );
          })
        );
      })
    );
  }

  getAllOrganisationInspections(): Observable<any> {
    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http
          .get(API + '/organisation/' + role.companyId + '/assignments', {
            headers: HEADERS,
            observe: 'response',
          })
          .pipe(
            map(response => {
              if (response.status === 204) return [];

              const assignmentsDto: any = response.body;

              const assigments: Assignment[] = assignmentsDto.map(
                (assignmentDto: any): Assignment => {
                  const inspectionsDto: any = assignmentDto.inspections;

                  const inspections = inspectionsDto.map(
                    (inspectionDto: any): Inspection => {
                      const activityDto = inspectionDto.activity;

                      const activity: Activity = {
                        id: activityDto.id,
                        scheme: activityDto.scheme,
                        place: activityDto.place,
                      };

                      const inspection: Inspection = {
                        id: inspectionDto.id,
                        activity: activity,
                        status: inspectionDto.status,
                      };

                      return inspection;
                    }
                  );

                  const assignment: Assignment = {
                    id: assignmentDto.id,
                    auditor: null,
                    inspections: inspections,
                    booked: assignmentDto.booked,
                    address: assignmentDto.address,
                    postalcode: assignmentDto.postalcode,
                    country: assignmentDto.country,
                    latitude: assignmentDto.longitude,
                    longitude: assignmentDto.latitude,
                    status: assignmentDto.status,
                  };

                  return assignment;
                }
              );

              return assigments;
            })
          );
      })
    );
  }

  getOrganisationInspectionByID(id: number): Observable<any> {
    if (!id) return new BehaviorSubject<Assignment>(null).asObservable();

    return this.authenticationService.getActiveRole().pipe(
      mergeMap(role => {
        return this.http
          .get(API + '/organisation/' + role.companyId + '/assignments/' + id, {
            headers: HEADERS,
          })
          .pipe(
            mergeMap((assignmentDto: any): Observable<Assignment> => {
              if (!assignmentDto) return new BehaviorSubject<Assignment>(null);

              let auditor: User = null;

              if (assignmentDto.auditor) {
                auditor = {
                  // id: assignmentDto.auditor.id,
                  firstName: assignmentDto.auditor.firstname,
                  lastName: assignmentDto.auditor.lastname,
                  email: assignmentDto.auditor.email,
                  mobile: assignmentDto.auditor.phone,
                };
              }

              const organisation: Organisation = {
                id: assignmentDto.organisation.id,
                // address: assignmentDto.organisation.address,
                email: assignmentDto.organisation.email,
                name: assignmentDto.organisation.name,
                // postalcode: assignmentDto.organisation.postalcode,
                // country: assignmentDto.organisation.country,
                phone: assignmentDto.organisation.phone,
              };

              if (
                !assignmentDto.inspections ||
                !assignmentDto.inspections.length
              ) {
                const assignment: Assignment = {
                  id: assignmentDto.id,
                  auditor: auditor,
                  organisation: organisation,
                  inspections: [],
                  booked: assignmentDto.booked,
                  address: assignmentDto.address,
                  country: assignmentDto.country,
                  latitude: assignmentDto.latitude,
                  longitude: assignmentDto.longitude,
                  postalcode: assignmentDto.postalcode,
                  status: assignmentDto.status,
                };

                return new BehaviorSubject<Assignment>(assignment);
              }

              const inspectionsObservables: Observable<Inspection>[] =
                assignmentDto.inspections.map(
                  (inspectionDto): Observable<Inspection> => {
                    const schemeApiRequest: ApiRequest<Scheme> =
                      new ApiRequest<Scheme>(
                        `{
                  id,
                  title
                }`
                      );

                    const membershipApiRequest: ApiRequest<Membership> =
                      new ApiRequest<Membership>(
                        `{
                  id,
                  title
                }`
                      );

                    const observables: Observable<any>[] = [];

                    const schemeObservables: Observable<Scheme> =
                      this.storeSchemeService.getById(
                        inspectionDto.activity.scheme,
                        schemeApiRequest
                      );

                    observables.push(schemeObservables);

                    if (inspectionDto.activity.memberships.length) {
                      const membershipsObservables: Observable<Membership>[] =
                        inspectionDto.activity.memberships.map(
                          (membershipDto: any): Observable<Membership> => {
                            return this.storeMembershipService.getById(
                              membershipDto,
                              membershipApiRequest
                            );
                          }
                        );
                      observables.push(combineLatest(membershipsObservables));
                    }

                    const inspectionObservable: Observable<Inspection> =
                      combineLatest(observables).pipe(
                        map((responses: any): Inspection => {
                          const scheme = responses.shift();
                          const memberships = responses.shift();

                          const member: User = {
                            id: inspectionDto.activity.member.id,
                            email: inspectionDto.activity.member.email,
                            firstName: inspectionDto.activity.member.firstname,
                            lastName: inspectionDto.activity.member.lastname,
                            mobile: inspectionDto.activity.member.phone,
                          };

                          const activity: Activity = {
                            id: inspectionDto.activity.id,
                            scheme: scheme,
                            memberships: memberships,
                            member: member,
                            place: inspectionDto.activity.place,
                          };

                          const inspection: Inspection = {
                            id: inspectionDto.id,
                            activity: activity,
                            status: inspectionDto.status,
                          };

                          return inspection;
                        })
                      );

                    return inspectionObservable;
                  }
                );

              const assignmentObservable: Observable<Assignment> =
                combineLatest(inspectionsObservables).pipe(
                  map((inspections: Inspection[]): Assignment => {
                    const assignment: Assignment = {
                      id: assignmentDto.id,
                      auditor: auditor,
                      organisation: organisation,
                      inspections: inspections,
                      booked: assignmentDto.booked,
                      address: assignmentDto.address,
                      country: assignmentDto.country,
                      latitude: assignmentDto.latitude,
                      longitude: assignmentDto.longitude,
                      postalcode: assignmentDto.postalcode,
                      status: assignmentDto.status,
                    };

                    return assignment;
                  })
                );

              return assignmentObservable;
            }),
            catchError(this.handleError<Assignment>(`get assignment id=${id}`))
          );
      })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(operation);
      console.error(error);
      return of(result as T);
    };
  }
}
