import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'basis';

  constructor(private authService: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.setActiveUserEvents();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event): void {
    // Auto logout if it's not refresh
    if (performance.navigation.type !== 1 && this.authService.getToken()) {
      this.authService.logout();
    }
  }

  setActiveUserEvents(): void {
    const activeEvents = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    activeEvents.forEach(event => {
      window.addEventListener(event, () => {
        this.authService.startIdleTimer();
      });
    });
  }
}
