import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { API, HEADERS } from './constants';
import { User } from '../models/user';
import { Role } from '../models/role';
import { catchError, map, tap } from 'rxjs/operators';
import { BE_V2_API } from 'src/environments/environment';
import { IFilter } from '../types/query';
import { IUpdateMigratedMemberDetails, IVerifyOldUserByBasisNumberRequest } from '../interfaces/migrationData';

const IDLE_TIMEOUT = 7200;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private user: BehaviorSubject<User>;
  private token: BehaviorSubject<string>;
  private id: BehaviorSubject<string>;
  private isIdSent: BehaviorSubject<boolean>;
  private activeRole: BehaviorSubject<Role>;

  private timer;
  private isShowLogoutConfirm: BehaviorSubject<boolean>;
  private isShowAutoLogoutConfirm: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.token = new BehaviorSubject<string>(sessionStorage.getItem('token'));
    this.user = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.id = new BehaviorSubject<string>(sessionStorage.getItem('id'));
    const activeRole = this.user.getValue() && this.user.getValue().roles[0];
    this.activeRole = new BehaviorSubject<Role>(activeRole);
    this.isIdSent = new BehaviorSubject<boolean>(false);
    this.isShowLogoutConfirm = new BehaviorSubject<boolean>(false);
    this.isShowAutoLogoutConfirm = new BehaviorSubject<boolean>(false);

    console.log(this.user.getValue());
  }

  setIsIdSent(isIdSent: boolean): void {
    this.isIdSent.next(isIdSent);
  }

  getIsIdSent(): boolean {
    return this.isIdSent.getValue();
  }

  setId(id: string): void {
    this.id.next(id);
  }

  getId(): string {
    return this.id.getValue();
  }

  setUser(user: User): void {
    this.user.next(user);
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUser(): User {
    return this.user.getValue();
  }

  getUserObservable(): Observable<User> {
    return this.user.asObservable();
  }

  setToken(token: string): void {
    this.token.next(token);
    sessionStorage.setItem('token', token);
  }

  getToken(): string {
    return this.token.getValue();
  }

  setActiveRole(activeRole: Role): void {
    sessionStorage.setItem('activeRole', JSON.stringify(activeRole));
    this.activeRole.next(activeRole);
  }

  getActiveRole(): Observable<Role> {
    const activeRole = sessionStorage.getItem('activeRole');
    if (activeRole) {
      this.activeRole.next(JSON.parse(activeRole));
    }
    return this.activeRole.asObservable();
  }

  getActiveRoleValue(): Role {
    const activeRole = sessionStorage.getItem('activeRole');
    if (activeRole) {
      this.activeRole.next(JSON.parse(activeRole));
    }
    return this.activeRole.getValue();
  }

  requestVerify(identity: string): Observable<any> {
    return this.http.post(
      API + '/verify',
      {
        identity: identity,
      },
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  requestAuthentication(identity: string, code: string, storeToken: boolean = true): Observable<any> {
    return this.http.put(
      API + '/verify',
      {
        identity: identity,
        code: code,
      },
      {
        headers: HEADERS,
        observe: 'response',
      }
    );
  }

  requestGetRenew(): Observable<any> {
    return this.http.get(API + '/verify/renew', {
      headers: HEADERS,
      observe: 'response',
    });
  }

  requestGetUser(): Observable<any> {
    return this.http
      .get(API + '/account', {
        headers: HEADERS,
        observe: 'response',
      })
      .pipe(
        map(response => {
          const userDto: any = response.body;

          const user: User = {
            firstName: userDto.firstname,
            lastName: userDto.lastname,
            mobile: userDto.mobile,
            email: userDto.email,
            country: userDto.country,
            postalcode: userDto.postalcode,
            address: userDto.address,
            dob: userDto.dob,
            marketing: userDto.marketing || [],
            disability: userDto.disability || [],
            roles: userDto.roles || [],
          };

          return user;
        })
      );
  }

  requestSendRegistrationData(
    token: string,
    mobile: string,
    firstName: string,
    lastName: string,
    postcode: string,
    country: string,
    address: string,
    dob: string,
    marketing: string[],
    disability: string[],
    oldbasisid: number
  ): Observable<any> {
    let body = {
      oldbasisid: oldbasisid,
      mobile: mobile,
      token: token,
      firstname: firstName,
      lastname: lastName,
      postalcode: postcode,
      country: country,
      address: address,
      dob: dob,
      marketing: marketing,
      disability: disability,
    };

    Object.keys(body).forEach(key => (!body[key] ? delete body[key] : null));

    console.log(body);

    return this.http.post(API + '/account', body, {
      headers: HEADERS,
      observe: 'response',
    });
  }

  requestUpdateRegistrationData(
    token?: string,
    mobile?: string,
    firstName?: string,
    lastName?: string,
    postcode?: string,
    country?: string,
    address?: string,
    email?: string,
    emailtoken?: string,
    dob?: string,
    marketing?: string[],
    disability?: string[]
  ): Observable<any> {
    let body = {
      mobile: mobile,
      token: token,
      firstname: firstName,
      lastname: lastName,
      postalcode: postcode,
      country: country,
      address: address,
      email: email,
      emailtoken: emailtoken,
      dob: dob,
      marketing: marketing,
      disability: disability,
    };

    Object.keys(body).forEach(key => (!body[key] ? delete body[key] : null));

    console.log(body);

    return this.http.put(API + '/account', body, {
      headers: HEADERS,
      observe: 'response',
    });
  }

  public getMemberProfile(filter: IFilter = {}): Observable<any> {
    return this.http.get(BE_V2_API + `/members/profile?filter=${JSON.stringify(filter)}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public updateMemberProfile(data): Observable<any> {
    return this.http.patch(BE_V2_API + `/members/profile`, data).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  registerMember(data): Observable<any> {
    return this.http.post(BE_V2_API + `/auth/member/register`, data).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  login(email: string, password: string): Observable<any> {
    const payload = { email, password };
    return this.http
      .post(BE_V2_API + `/auth/login`, payload, {
        responseType: 'text',
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  logout(): void {
    // clear access token
    this.http.get(BE_V2_API + `/auth/logout`).subscribe();
    this.setToken(null);
    sessionStorage.removeItem('token');
    this.setUser(null);
    sessionStorage.removeItem('currentUser');
    this.setId(null);
    sessionStorage.removeItem('id');
    this.stopIdleTimer();
    sessionStorage.removeItem('activeRole');
  }

  /* ------------ Auto logout ------------- */

  // Start or reset the auto logout timer
  startIdleTimer(): void {
    this.stopIdleTimer(); // Stop the auto logout timer if already running
    // Start the timer only if already logged in
    if (this.getToken()) {
      this.timer = setTimeout(() => {
        this.stopIdleTimer();
        this.setIsShowAutoLogoutConfirm(true);
      }, IDLE_TIMEOUT * 1000);
    }
  }

  // Stop the auto logout timer
  stopIdleTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setIsShowLogoutConfirm(show: boolean): void {
    this.isShowLogoutConfirm.next(show);
  }

  getIsShowLogoutConfirm(): boolean {
    return this.isShowLogoutConfirm.getValue();
  }

  setIsShowAutoLogoutConfirm(show: boolean): void {
    this.isShowAutoLogoutConfirm.next(show);
  }

  getIsShowAutoLogoutConfirm(): boolean {
    return this.isShowAutoLogoutConfirm.getValue();
  }

  /* ------------ Auto logout ------------- */

  public sendForgotPasswordEmail(email: string): Observable<any> {
    return this.http.get(BE_V2_API + `/mail/send-forgot-password/${email}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public sendInvitationEmail(data): Observable<any> {
    return this.http.post(BE_V2_API + `/mail/send-invitations`, data).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public setPassword(data): Observable<any> {
    return this.http
      .post(BE_V2_API + `/auth/set-password`, data, {
        responseType: 'text',
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public resetPassword(data): Observable<any> {
    return this.http
      .post(BE_V2_API + `/auth/reset-password`, data, {
        responseType: 'text',
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public verifyToken(token: string): Observable<any> {
    return this.http.get(BE_V2_API + `/auth/verify-token/${token}`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public verifyValidOldUserByAccountNumber(data: IVerifyOldUserByBasisNumberRequest): Observable<any> {
    const { accountNumber, dateOfBirth } = data;
    return this.http.get(BE_V2_API + `/auth/old-user/account-number/${accountNumber}/dob/${dateOfBirth}/is-valid`).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public updateOldUserDetails(
    verifyPayload: IVerifyOldUserByBasisNumberRequest,
    updatePayload: IUpdateMigratedMemberDetails
  ): Observable<any> {
    const { accountNumber, dateOfBirth } = verifyPayload;
    return this.http
      .patch(BE_V2_API + `/auth/old-user/account-number/${accountNumber}/dob/${dateOfBirth}/details`, updatePayload, {
        responseType: 'text',
      })
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(operation);
      console.error(error);
      return of(result as T);
    };
  }
}
