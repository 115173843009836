import { HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock";

export class VerifyApiMockRootRoute implements ApiMockRootRoute {

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.responseCallback = this.getResponseCallback();
    }

    private getResponseCallback(): ApiMockResponseCallback<ObjectAny[], ObjectAny[]> {
        console.log('HTTP api/verify');
        return ({ reqBody, httpMethod }) => {

            if (httpMethod == 'POST') {
                return new HttpResponse({
                    body: { message: 'confirm identity' },
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user found',
                    status: 200,
                });
            }
            else if (httpMethod == 'PUT') {

                if (reqBody.identity === '+441234567890' && reqBody.code === '123456') {

                    // token for registered user
                    return new HttpResponse({
                        body: {
                            message: "Identity Confirmed",
                            token: 'ayJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlVzZXJOYW1lIiwiZmlyc3ROYW1lIjoiVXNlckZpc3ROYW1lIiwibGFzdE5hbWUiOiJVc2VyTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJuYW1lQGdtYWlsLmNvbSJ9.eiUupOFXIOkzlKiMiE2r1PBggAKdt9uAFZWO6Hp4uME'
                        },
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'user found',
                        status: 200,
                    });
                }
                else if (reqBody.code === '123456') {

                    // token for not registered user
                    return new HttpResponse({
                        body: {
                            message: "Identity Confirmed",
                            token: 'byJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlVzZXJOYW1lIiwiZmlyc3ROYW1lIjoiVXNlckZpc3ROYW1lIiwibGFzdE5hbWUiOiJVc2VyTGFzdE5hbWUiLCJlbWFpbCI6InVzZXJuYW1lQGdtYWlsLmNvbSJ9.eiUupOFXIOkzlKiMiE2r1PBggAKdt9uAFZWO6Hp4uME'
                        },
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'user found',
                        status: 200,
                    });
                }
                else {
                    // wrong code
                    return new HttpErrorResponse({
                        error: 'Unauthorised',
                        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                        url: 'api/register',
                        statusText: 'wrong code',
                        status: 400,
                    });
                }
            }
        }
    }
}