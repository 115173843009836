import { Component, OnInit } from '@angular/core';
import { ComponentItem } from 'src/app/interfaces/component-item';
import { SplashService } from 'src/app/services/provider';
import { DashboardItemComponent } from '../../molecules/dashboard-item/dashboard-item.component';
import { IconsComponent } from '../../particles/icons/icons.component';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { BannerSubtitleLabelComponent } from '../labels/molecules/banner/banner-subtitle-label/banner-subtitle-label.component';
import { BannerTitleLabelComponent } from '../labels/molecules/banner/banner-title-label/banner-title-label.component';
import { DashboardItemTitleLabelComponent } from '../labels/molecules/dashboard-item/dashboard-item-title-label/dashboard-item-title-label.component';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  theme: string = 'dark';

  constructor(private splashService: SplashService) {
    let storedMode = sessionStorage.getItem('darkMode');
    if(storedMode){
      this.theme = storedMode;
    }
    else{
      sessionStorage.setItem('darkMode',this.theme)
    }
  }

  ngOnInit(): void {
    this.splashService.setSplash(false);
  }

  setTheme(){
    this.theme = this.theme === "dark"? "light" : "dark";
    sessionStorage.setItem('darkMode',this.theme)
  }

  //Testing data
  dashboardItems = [
    {
        icon: 'cog-solid',
        label: 'Settings',
        componentData: {
          label:'GO TO SETTINGS',
          type:'secondary',
          enable: true
        }
    },
    {
        icon: 'cog-solid',
        label: 'Settings',
        componentData: {
          label:'GO TO SETTINGS',
          type:'secondary',
          enable: true
        }
    },
    {
        icon: 'cog-solid',
        label: 'Settings',
        componentData: {
          label:'GO TO SETTINGS',
          type:'secondary',
          enable: true
        }
    },
    {
        icon: 'cog-solid',
        label: 'Settings',
        componentData: {
          label:'GO TO SETTINGS',
          type:'secondary',
          enable: true
        }
    },
    {
        icon: 'cog-solid',
        label: 'Settings',
        componentData: {
          label:'GO TO SETTINGS',
          type:'secondary',
          enable: true
        }
    }
  ]
}


