import { Component, Input, OnInit } from '@angular/core';
import { ComponentItem } from 'src/app/interfaces/provider';
import { DashboardItemComponent } from '../../molecules/dashboard-item/dashboard-item.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @Input() data: any
  dashboardItems: Array<ComponentItem> = [];

  constructor() { }

  ngOnInit(): void {
    this.data.forEach(item => {
      this.dashboardItems.push({
        component: DashboardItemComponent,
        data:{
          icon: item.icon,
          label: item.label,
          componentData: item.componentData
        }
      })
    })
  }

}
