import { HttpResponse, HttpHeaders } from "@angular/common/http";
import { ApiMockRootRoute, ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute } from "@ng-stack/api-mock";

export class QuestionApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "Provide details",
            "id": 2,
            "question": "Provide details"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TIME",
            "category_id": 4,
            "guidance": "",
            "id": 3,
            "question": "What time did you arrive for the Assessment",
            "question_id": "0.0.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "PPE of high vis vest and safety shoes are essential for any site visit for your own protection.  You should also carry your BASIS Auditor ID card",
            "id": 4,
            "question": "Are you wearing PPE as advised by BASIS and do you have your BASIS auditor identification card avaiable.",
            "question_id": "0.0.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "INTEGER",
            "category_id": 2,
            "guidance": "Qualified storekeepers should be available at the store as a way of demonstrating compliance with the requirement of the PPP(SU) Regulations for suitably trained staff.  Retraining is recommended if the storekeeper has an exemption or has not seen the transport security video (3-5years)",
            "id": 5,
            "question": "Is there a Nominated Storekeeper available at the site",
            "question_id": "0.1.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 6,
            "question": "Who is the Senior Nominated Storekeeper",
            "question_id": "0.1.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 7,
            "question": "What is their contact telephone number at the premise",
            "question_id": "0.1.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 8,
            "question": "What is their email address at the premise",
            "question_id": "0.1.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 9,
            "question": "Who was the assessment conducted with",
            "question_id": "0.1.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Change of NSK",
                    "failscore": 30,
                    "subquestion": [
                        2
                    ]
                },
                {
                    "answer": "Change of premise phone number",
                    "failscore": 30,
                    "subquestion": [
                        2
                    ]
                },
                {
                    "answer": "Change of premise email",
                    "failscore": 30,
                    "subquestion": [
                        2
                    ]
                },
                {
                    "answer": "Change of other information (record as observation)",
                    "failscore": 30,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 10,
            "question": "Has the above information changed since the last audit",
            "question_id": "0.1.6"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In Store Agronomist",
                    "failscore": 20
                },
                {
                    "answer": "Head Office Agronomist",
                    "failscore": 20
                },
                {
                    "answer": "Retained Consultant",
                    "failscore": 20
                },
                {
                    "answer": "Other (Record as an observation)",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 1,
            "guidance": "Advice on product choice and/or recommendations can only be given by a BASIS qualified adviser.  From November 2015 this must be someone under formal agreement or employed by the company and easily contactable.  (They do not necessarily need to be employed directly)",
            "id": 11,
            "question": "Is the Storekeeper aware of who to contact should product advice be required (BASIS Certificate in Crop Protection holder)",
            "question_id": "0.1.7"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "DATE",
            "category_id": 2,
            "guidance": "It is necessary to obtain an initial letter of approval from the Environment Agency If the distributor is having difficulty in getting a response from the EA, BASIS will assist on request.  BASIS routinely chases out of date letters directly with the authority.  Once initial approval is granted no further visits are required unless deemed essential.  The ERA MUST be completed in its place and sent in to the BASIS office together with the ECP",
            "id": 12,
            "question": "Is the store approved or acknowledged by the Environment Agency",
            "question_id": "1.1.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "If there is a non-approval on site from the EA, the process is slightly different.  BASIS cannot go down the ERA route or pass the store.  The site operator MUST contact the EA to arrange a re-inspection or approval for the site.  BASIS cannot help in this situation.  The EA want the company to contact them.  Please refer back to the office if you have any queries.",
            "id": 13,
            "question": "Is there a current NON-APPROVAL from the EA on this site",
            "question_id": "1.1.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "An Environmental Risk assessment is now compulsory in all BASIS stores to help store operators understand and identify environmental risks around their storage facilities  The document was produced by BASIS in partnership with the EA and is available from your Auditor or from the BASIS office.  The document MUST be kept with the ECP and sent in to BASIS for retention on your file  This is now compulsory for all distributor stores in 2018 and a compliance issue.",
            "id": 14,
            "question": "Is there a current completed Environmental Risk Assessment",
            "question_id": "1.1.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No action required",
                    "failscore": 30
                },
                {
                    "answer": "Company needs to pursue EA for approval/acknowledgement",
                    "failscore": 30
                },
                {
                    "answer": "Company needs to request an inspection from the EA",
                    "failscore": 30
                },
                {
                    "answer": "BASIS to pursue letter of acknowledgement",
                    "failscore": 30
                },
                {
                    "answer": "BASIS to request an inspection",
                    "failscore": 30
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 15,
            "question": "Does contact need to be made with the Environment Agency",
            "question_id": "1.1.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "DATE",
            "category_id": 2,
            "guidance": "It is necessary to obtain an initial letter of approval from the Fire Officer.   If the store is over 60,000l the owner/occupier must ask the Fire Authority to inspect the site.  NFCC have agreed nationally to do this.  BASIS can help if distributors are having difficulty getting a response from the Authority.  BASIS routinely chases all out of date letters directly with the authority.",
            "id": 16,
            "question": "Is the store approved or acknowledged by the Fire Authority",
            "question_id": "1.2.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 17,
            "question": "Is there a current NON-APPROVAL from the FRS on this site",
            "question_id": "1.2.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No action required",
                    "failscore": 30
                },
                {
                    "answer": "Company needs to pursue FRS for approval/acknowledgement",
                    "failscore": 30
                },
                {
                    "answer": "Company needs to request an inspection from the FRS",
                    "failscore": 30
                },
                {
                    "answer": "BASIS to pursue letter of acknowledgement",
                    "failscore": 30
                },
                {
                    "answer": "BASIS to request an inspection",
                    "failscore": 30
                }
            ],
            "category_id": 4,
            "guidance": "If the store is under 60,000l and has a current, full Fire Risk Assessment BASIS will pursue the letter of approval from the Fire Authority if they are having difficulty. BASIS routinely chases the authority for acknowledgement or letter of approval",
            "id": 18,
            "question": "Does contact need to made with the Fire Authority",
            "question_id": "1.2.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not available",
                    "failscore": 20
                },
                {
                    "answer": "Out of date",
                    "failscore": 20
                },
                {
                    "answer": "Not site specific",
                    "failscore": 20
                },
                {
                    "answer": "Pollution measures not described",
                    "failscore": 20
                },
                {
                    "answer": "Needs review",
                    "failscore": 20
                },
                {
                    "answer": "Drainage plan needed",
                    "failscore": 20
                },
                {
                    "answer": "Site plan needed (including store dimension and volume)",
                    "failscore": 20
                },
                {
                    "answer": "Full contact page needed",
                    "failscore": 20
                },
                {
                    "answer": "Licensed waste disposal contractor details needed",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "It is recommended that the ECP is reviewed once a year, signed and dated.  This is essential if the site is under a ERA.  Please check the plan to ensure it meets the minimum standard of information and includes a site plan (including the store dimension and volume), a drainage plan showing flow direction and has emergency contact numbers listed. A typical stock list should also be recorded.",
            "id": 19,
            "question": "Is there a current suitable Emergency Contingency Plan",
            "question_id": "1.3.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, copies to be sent to Environment Agency, Fire and Police Authorities if required.",
            "id": 20,
            "question": "Do Authorities have a copy of the Contingency Plan",
            "question_id": "1.3.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, copy to be kept in store for reference by staff in the event of an incident and to allow them to familiarise themselves with it.",
            "id": 21,
            "question": "Is the Emergency plan easily accessible to staff",
            "question_id": "1.3.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "Ideally an emergency contingency plan should be tested to check it works (not a compliance issue for 2019)",
            "id": 22,
            "question": "Is the Emergency Contingency Plan tested to ensure it is appropriate and actionable",
            "question_id": "1.3.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If a store comes under NAMOS regulations, this must be noted in the ECP.  Notification must be sent to the appropriate authorities. Signage does not always have to be visible.  The notification to the authorities is the crucial bit.",
            "id": 23,
            "question": "Notification of NAMOS (Notification and Marking of Sites) seen in Emergency Contingency Plan if applicable",
            "question_id": "1.3.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "BASIS version to be sent and completed",
                    "failscore": 20
                },
                {
                    "answer": "Company needs to complete and send a copy to BASIS",
                    "failscore": 20
                },
                {
                    "answer": "Needs update and review",
                    "failscore": 20
                },
                {
                    "answer": "Needs signing and dating",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "If No, a full Fire Risk Assessment must be kept for the store.  This is a requirement under the Fire Safety Order 2005 and Fire Authorities insist that this in place for all businesses regardless of the amount of people employed. Thorough guides are available through Fire & Rescue service websites to download although these are not specifically for pesticide stores, they are adequate if completed fully. BASIS also has an approved template document",
            "id": 24,
            "question": "Has the written Fire Risk Assessment been reviewed and updated in the last year",
            "question_id": "1.4.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A degree of common sense and advice from the local fire safety officer must be followed.  An audible means could in fact be someone shouting for small premises. The company should record this in their Fire Risk Assessment or Contingency Plan",
            "id": 25,
            "question": "Is there an audible means of raising the alarm in the event of a fire",
            "question_id": "1.4.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "Smoke detectors are not always necessary - please refer to advice from the fire safety officer",
            "id": 26,
            "question": "Are there smoke/fire detectors in the store",
            "question_id": "1.4.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  BASIS can help to identify the correct signs but advice should be taken form the Fire Prevention Officer of the local Fire and Rescue Service when possible.",
            "id": 27,
            "question": "Do fire exit signs in the store comply with current Regulations",
            "question_id": "1.4.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Annual",
                    "failscore": 20
                },
                {
                    "answer": "Bi-annual",
                    "failscore": 20
                },
                {
                    "answer": "Quarterly",
                    "failscore": 20
                },
                {
                    "answer": "Monthly",
                    "failscore": 20
                },
                {
                    "answer": "Occasional",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If No, fire drills should be held and recorded, as required by the Fire Safety Order 2005.",
            "id": 28,
            "question": "Are there regular recorded fire drills",
            "question_id": "1.4.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Company format",
                    "failscore": 20
                },
                {
                    "answer": "Contracted format",
                    "failscore": 20
                },
                {
                    "answer": "Standard Fire Action sign",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  This should be displayed clearly for example on a main notice board and in staff areas and in areas accessible by the public or visitors",
            "id": 29,
            "question": "Is the fire procedure displayed",
            "question_id": "1.4.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Provision of Information to employees - Article 19 of the Fire Safety Order 2005 -  enforced by the Fire Authority.",
            "id": 30,
            "question": "Are staff made aware of fire precautions and procedures",
            "question_id": "1.4.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Staff Training  - Article 21 of the Fire Safety Order 2005 - enforced by the Fire Authority",
            "id": 31,
            "question": "Do staff receive regular training on emergency actions for fire",
            "question_id": "1.4.8"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "Any concerns must be recorded under corrective actions as these will be reported back to the local fire authority for investigation",
            "id": 32,
            "question": "Have you any concerns regarding the fire arrangements at this site",
            "question_id": "1.4.9"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Secure site",
                    "failscore": 20
                },
                {
                    "answer": "Alarmed",
                    "failscore": 20
                },
                {
                    "answer": "Locked",
                    "failscore": 20
                },
                {
                    "answer": "Alarmed and locked",
                    "failscore": 20
                },
                {
                    "answer": "Security lights, locked store",
                    "failscore": 20
                },
                {
                    "answer": "CCTV",
                    "failscore": 20
                },
                {
                    "answer": "Security patrols",
                    "failscore": 20
                },
                {
                    "answer": "Other approved",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 33,
            "question": "Describe the general security",
            "question_id": "1.5.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "Yes - Routine",
                    "failscore": 20
                },
                {
                    "answer": "Yes - incident on-site",
                    "failscore": 20
                },
                {
                    "answer": "Yes - Incident in locality",
                    "failscore": 20
                },
                {
                    "answer": "Yes - Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "The Crime Prevention Officer usually visits if a previous incident has occurred or if the Police are concerned.",
            "id": 34,
            "question": "Has the Crime Prevention Officer visited since the last assessment",
            "question_id": "1.5.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not visited",
                    "failscore": 20
                },
                {
                    "answer": "Visited due to fertiliser",
                    "failscore": 20
                },
                {
                    "answer": "Visited due to others",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 35,
            "question": "If the Anti Terrorism Unit has visited, please give reason why",
            "question_id": "1.5.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "There has not been an inspection",
                    "failscore": 20
                },
                {
                    "answer": "Inspected by HSE",
                    "failscore": 20
                },
                {
                    "answer": "Inspected by EHO",
                    "failscore": 20
                },
                {
                    "answer": "Inspected by Trading Standards",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "If any inspections have taken place, please detail the reasons and findings as a corrective action",
            "id": 36,
            "question": "Has there been a Health & Safety Executive or Environmental Health Officer/Trading Standards Inspection within the last 12 months",
            "question_id": "1.5.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, obtain evidence that there is Public Liability although some branches of the larger distributors do not know whether this is held. It is highly unusual to find a distributor without any Public Liability Insurance.",
            "id": 37,
            "question": "Is there Public Liability Insurance",
            "question_id": "1.6.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "Please note that with company numbers of 1-4, a verbal statement of Company Health and Safety must be communicated to all staff.",
            "id": 38,
            "question": "What is the maximum number of employees on site",
            "question_id": "1.6.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 39,
            "question": "If there are more than five employees, is there a current written Health & Safety Policy available",
            "question_id": "1.6.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Partnership",
                    "failscore": 20
                },
                {
                    "answer": "Sole trader",
                    "failscore": 20
                },
                {
                    "answer": "Statement only",
                    "failscore": 20
                },
                {
                    "answer": "Under 5 employees",
                    "failscore": 20
                }
            ],
            "category_id": 3,
            "guidance": "Legislatively the policy should be reviewed every 3 years but BASIS advises that for good practice and to take account of any changes to the business , the Policy should be reviewed every 12 months in line with other documentation",
            "id": 40,
            "question": "Has the written Health & Safety Policy or statement been updated in the last twelve months",
            "question_id": "1.6.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "It is purely good practice to have employees sign to say they have understood the policy.  Perhaps at the time of their induction or appraisal.",
            "id": 41,
            "question": "Have the employees signed the Health & Safety Policy",
            "question_id": "1.6.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Standard",
                    "failscore": 20
                },
                {
                    "answer": "Electronic",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "The accident book should be in a form type format with tear out pages to ensure details are kept confidential under data protection or compliant electronic system.  Near misses should also be recorded and reported.",
            "id": 42,
            "question": "Is an Accident Book of the correct format available",
            "question_id": "1.6.6"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not displayed",
                    "failscore": 20
                },
                {
                    "answer": "Incomplete",
                    "failscore": 20
                },
                {
                    "answer": "Not up to date",
                    "failscore": 20
                },
                {
                    "answer": "Needs new format",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "If No, all details must be completed.  New format must be displayed from 5 April 2014.",
            "id": 43,
            "question": "Is the HASWA poster displayed, correct and completed",
            "question_id": "1.6.7"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Needs Review",
                    "failscore": 20
                },
                {
                    "answer": "Not Suitable and sufficient",
                    "failscore": 20
                },
                {
                    "answer": "Does not include all products stored",
                    "failscore": 20
                },
                {
                    "answer": "BASIS template to be sent",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "BASIS require this to be reviewed annually as with the other paperwork. A regular review is a good idea to take into account any new pesticide products and the precautions needed to deal with them in the event of an incident. The Assessment must cover the products stored.  The legislative requirement is for a five yearly review. COSHH should be pragmatic and a separate assessment is NOT required for each product providing Emergency procedures are suitable and sufficient.",
            "id": 44,
            "question": "Has the written COSHH assessment been revised and reviewed",
            "question_id": "1.6.8"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "An accurate and up-to-date list of the quantity and types of pesticide stored should be kept.",
            "id": 45,
            "question": "Is there an on-site inventory available",
            "question_id": "1.6.9"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Copy with key holder",
                    "failscore": 20
                },
                {
                    "answer": "Adjacent to store",
                    "failscore": 20
                },
                {
                    "answer": "Stock records held centrally",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 3,
            "guidance": "It is recommended that an ‘off-site’ inventory, listing all the pesticide stock which may be on the premises, be made available in case of an incident, where the fire or environment agency may need to know what and how much is on site usually. This does not need to be hard copy but should be able to be accessed easily",
            "id": 46,
            "question": "Is a copy of the inventory also accessible off-site",
            "question_id": "1.6.10"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "CRD Website",
                    "failscore": 20
                },
                {
                    "answer": "UK Pesticide Guide",
                    "failscore": 20
                }
            ],
            "category_id": 3,
            "guidance": "It is an offence to store a pesticide which does not carry a current approval, unless it is intended solely for export.  Therefore The UK Pesticide Guide (the current annual edition) CAB/BCPC, obtainable from BCPC Publications, Bear Farm, Binfield, Bracknell, Berkshire, RG42 5QE, telephone 0118 934 2727 or the CRD website www.pesticides.gov.uk must be used for reference.",
            "id": 47,
            "question": "Is reference available for approved pesticides",
            "question_id": "1.7.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "None stored",
                    "failscore": 20
                },
                {
                    "answer": "Aluminium Phosphide",
                    "failscore": 20
                },
                {
                    "answer": "Magnesium Phosphide",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 48,
            "question": "Which Poisons are stored",
            "question_id": "1.8.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "To comply with the Poisons Rules, sales of all poisons MUST be recorded in a Poisons Register and details of date of sale, name and quantity of poison supplies, name of purchaser, business, trade or occupation, purpose for which stated to be required, signature or date / ref no. of signed order bearing signature, MUST all be recorded. From 2013 flask numbers and certificate numbers should also be recorded - registers are available through RAMPS",
            "id": 49,
            "question": "Was the Poisons Register available",
            "question_id": "1.8.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No date of sale",
                    "failscore": 20
                },
                {
                    "answer": "No name and quantity of poison supplied",
                    "failscore": 20
                },
                {
                    "answer": "No name of purchaser",
                    "failscore": 20
                },
                {
                    "answer": "No address",
                    "failscore": 20
                },
                {
                    "answer": "No business, trade or occupation",
                    "failscore": 20
                },
                {
                    "answer": "No purpose of use",
                    "failscore": 20
                },
                {
                    "answer": "No signature of purchaser or signed order number and date",
                    "failscore": 20
                },
                {
                    "answer": "No recorded flask number",
                    "failscore": 20
                },
                {
                    "answer": "No recorded certificate number",
                    "failscore": 20
                },
                {
                    "answer": "Unsuitable Register",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "If No, full details must be kept to ensure the register entries comply.",
            "id": 50,
            "question": "Are all the details entered in the Register compliant",
            "question_id": "1.8.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "It is good practice (endorsed by RAMPS and BASIS) for the seller to be happy that the purchaser is appropriately qualified to use the poison and so as to show that reasonable care has been taken, BASIS advises that a copy of the certificate is retained as proof. Appropriate qualifications are listed on the CRD website under Annex 1. (Lantra, City & Guilds Level 2 and RSPH Level 2)",
            "id": 51,
            "question": "Is a copy of proof of certification of all purchases kept on file",
            "question_id": "1.8.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If deliveries of Poisons to a purchaser are made, a Signed Order bearing a signature must be used.",
            "id": 52,
            "question": "Are Signed Orders used for deliveries",
            "question_id": "1.8.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "When making a Poisons transaction using a Poisons Signed Order, the details of the sale are entered into the Poisons Register and on the Signed Order.  The appropriate signature is collected on the Poisons Signed Order, either at the time of delivery or returned within 72 hours to the distributor.  The Reference No./date of the Signed Order is then entered into the signature column of the Poisons Register.  Poisons Signed Orders should then be filed in date sequence for easy reference by the Authorities when inspecting the register.  The information which must be recorded on the Poisons Signed Order is the same as for the poisons register and includes the purchaser’s occupation and his/her intended use for the product purchased. It is essential to detail this information.",
            "id": 53,
            "question": "Were the details of the Signed Order referenced in the Poisons Register",
            "question_id": "1.8.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "The legislation insists on the signed orders being kept with the Poisons Register (Can be electronic)",
            "id": 54,
            "question": "Are Signed Orders kept with or near to the Poisons Register",
            "question_id": "1.8.7"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Only to professional users",
                    "failscore": 20
                },
                {
                    "answer": "Only to certificate holders",
                    "failscore": 20
                },
                {
                    "answer": "Delivery only",
                    "failscore": 20
                },
                {
                    "answer": "Collection from store doorway",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "The supplier must be satisfied that the purchaser can safely apply the product and store any surplus safely.  In addition, they must possess the necessary certification to work with pesticides approved for agricultural, horticultural, forestry and amenity use.",
            "id": 55,
            "question": "Are professional sales precautions adopted",
            "question_id": "1.8.8"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Purpose built poisons cabinet",
                    "failscore": 20
                },
                {
                    "answer": "Adapted but suitable poisons cabinet",
                    "failscore": 20
                },
                {
                    "answer": "Unsuitable - note as an observation and correction",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.  It is a requirement of the Code of Practice, that Phostoxin / Talunex be stored in the bunded area of the store in a separate metal box, marked 'Gassing Compound - Do not use Water'.  This must be secure, lockable, dry (moisture and waterproof) and elevated to at least the height of the bund.  This will help to obviate the hazard.",
            "id": 56,
            "question": "Are Part 1 Poisons stored in a locked metal box with a water-shedding lid",
            "question_id": "1.9.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "The Poisons box should be secure from members of the general public.  Some fire authorities don't want the box fixed to the wall or racking as they would move it in the event of a fire!! The box should be kept locked and access restricted at all times.",
            "id": 57,
            "question": "Is the Poisons box secure",
            "question_id": "1.9.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 58,
            "question": "Is the Poisons box positioned above the height of the bund",
            "question_id": "1.9.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "A sign must be fixed on the outside of the box.  Suitable signs are available from the BASIS office by request",
            "id": 59,
            "question": "Is there a \"Gassing Compound - Do Not Use Water\" sign on the Poisons box",
            "question_id": "1.9.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "INTEGER",
            "category_id": 4,
            "guidance": "",
            "id": 60,
            "question": "How many flasks can be stored in the container /box",
            "question_id": "1.9.5"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "INTEGER",
            "category_id": 4,
            "guidance": "",
            "id": 61,
            "question": "What was the stock level of the poisons store at the time of assessment",
            "question_id": "1.9.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                },
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "If the premise is selling professional rodenticides they should be registered for RPOS.  Please make it clear that we are only the auditors and do not run the scheme.  If they are not registered tell them why they should be but we cannot enforce this.",
            "id": 62,
            "question": "Do you sell Professional Rodenticides",
            "question_id": "1.10.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No Changes",
                    "failscore": 30
                },
                {
                    "answer": "Yes - relocated on site",
                    "failscore": 30
                },
                {
                    "answer": "Yes - increase in bund capacity",
                    "failscore": 30
                },
                {
                    "answer": "Yes - decrease in bund capacity",
                    "failscore": 30
                },
                {
                    "answer": "Yes - New store",
                    "failscore": 30
                }
            ],
            "category_id": 4,
            "guidance": "If Yes, record changes as a corrective action.",
            "id": 63,
            "question": "Have there been any changes to the store since the last BASIS visit",
            "question_id": "2.1.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Self-contained building",
                    "failscore": 20
                },
                {
                    "answer": "Store inside another building",
                    "failscore": 20
                },
                {
                    "answer": "Container-type store - inside the building",
                    "failscore": 20
                },
                {
                    "answer": "Container-type store - outside the building",
                    "failscore": 20
                },
                {
                    "answer": "Chemsafe - inside the building",
                    "failscore": 20
                },
                {
                    "answer": "Chemsafe - outside the building",
                    "failscore": 20
                },
                {
                    "answer": "Chemsafe - within a container-type store",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 64,
            "question": "Describe the store",
            "question_id": "2.1.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "N/A",
                    "failscore": 20
                },
                {
                    "answer": "1",
                    "failscore": 20
                },
                {
                    "answer": "2",
                    "failscore": 20
                },
                {
                    "answer": "3",
                    "failscore": 20
                },
                {
                    "answer": "4",
                    "failscore": 20
                },
                {
                    "answer": "5",
                    "failscore": 20
                },
                {
                    "answer": "6",
                    "failscore": 20
                },
                {
                    "answer": "7",
                    "failscore": 20
                },
                {
                    "answer": "8",
                    "failscore": 20
                },
                {
                    "answer": "9",
                    "failscore": 20
                },
                {
                    "answer": "10",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 65,
            "question": "How many container stores or chemsafes are there",
            "question_id": "2.1.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "",
            "id": 66,
            "question": "Is the store(s) fully enclosed",
            "question_id": "2.1.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Access must be clear.  If it is not, please raise a corrective action",
            "id": 67,
            "question": "Is access to the store(s) clear",
            "question_id": "2.1.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 68,
            "question": "Is the store clean and tidy",
            "question_id": "2.1.6"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Steel",
                    "failscore": 20
                },
                {
                    "answer": "Block",
                    "failscore": 20
                },
                {
                    "answer": "Brick",
                    "failscore": 20
                },
                {
                    "answer": "Concrete Panels",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 69,
            "question": "What is the structure of the store retention walls",
            "question_id": "2.1.7"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No ceiling",
                    "failscore": 20
                },
                {
                    "answer": "Yes - asbestos type",
                    "failscore": 20
                },
                {
                    "answer": "Yes - fire resistant plaster board",
                    "failscore": 20
                },
                {
                    "answer": "Yes - skimmed plaster board",
                    "failscore": 20
                },
                {
                    "answer": "Yes - steel",
                    "failscore": 20
                },
                {
                    "answer": "Yes - wood",
                    "failscore": 20
                },
                {
                    "answer": "Yes - acceptable other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 70,
            "question": "Is there a ceiling",
            "question_id": "2.1.8"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "Please record. This may mean that a 30 minute fire resistant structure of walls, windows, doors and ceilings would be required.  The distributor is advised to discuss these aspects of the store with the Fire Safety Officer.",
            "id": 71,
            "question": "Are there any features without 30 minutes fire resistance",
            "question_id": "2.1.9"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "Bunding should be passive and permanent. Please highlight any other forms of bunding with a detailed description and photos as an observation",
            "id": 72,
            "question": "Have you any concerns relating to the bunding of this site",
            "question_id": "2.1.10"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Ramp",
                    "failscore": 20
                },
                {
                    "answer": "Wall",
                    "failscore": 20
                },
                {
                    "answer": "Sump",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable (record as observation)",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                },
                {
                    "answer": "Moveable spill or flood barrier",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "PERMANENT bunding is NORMALLY required.  For example, concrete ramps at vehicular access doors and masonry or concrete steps or walls at communicating and fire exit doors. If moveable bunding is present this MUST be approved by the EA before BASIS can approve.",
            "id": 73,
            "question": "Is the store(s) permanently bunded",
            "question_id": "2.1.11"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Block bund",
                    "failscore": 20
                },
                {
                    "answer": "Step bund",
                    "failscore": 20
                },
                {
                    "answer": "Ramp bund",
                    "failscore": 20
                },
                {
                    "answer": "Wall bund",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "The doors must be bunded or above the height of the bund.  If they are not bunded please record as a corrective action. If 'other' please record as an observation.",
            "id": 74,
            "question": "Are all doors in the store bunded",
            "question_id": "2.1.12"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Block",
                    "failscore": 20
                },
                {
                    "answer": "Brick",
                    "failscore": 20
                },
                {
                    "answer": "Steel",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 75,
            "question": "Type of bunding of the store",
            "question_id": "2.1.13"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Epoxy resin treated",
                    "failscore": 20
                },
                {
                    "answer": "Mechanical float finish",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "Floors should be impermeable to liquids, be provided with an anti-slip surface, be easily cleaned and resistant to chemical attack either by mechanical finish or by being painted with a suitable epoxy resin based paint.",
            "id": 76,
            "question": "Are the storage area floors appropriately sealed",
            "question_id": "2.1.14"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Floor worn",
                    "failscore": 20
                },
                {
                    "answer": "Floor cracked",
                    "failscore": 20
                },
                {
                    "answer": "Floor damaged",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Worn or damaged areas of the floor must be re-treated to ensure the integrity of the sealed area. Any cracks should be filled and resealed.",
            "id": 77,
            "question": "Is the condition of the bunding of the floor compliant with recognised standards",
            "question_id": "2.1.15"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Sealed rendered block",
                    "failscore": 20
                },
                {
                    "answer": "Sealed rendered brick",
                    "failscore": 20
                },
                {
                    "answer": "Sealed rendered walls",
                    "failscore": 20
                },
                {
                    "answer": "Steel",
                    "failscore": 20
                },
                {
                    "answer": "Epoxy coated",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "The walls enclosing the storage area should be impermeable to pesticide to a height of at least any bunding designed to prevent leakage of pesticide or contaminated water",
            "id": 78,
            "question": "Are the walls bunded and sealed",
            "question_id": "2.1.16"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Walls worn",
                    "failscore": 20
                },
                {
                    "answer": "Walls cracked",
                    "failscore": 20
                },
                {
                    "answer": "Walls damaged",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If the walls are cracked or severely damaged this must be rectified to ensure the integrity of the bund.  Please record any action required as a corrective action",
            "id": 79,
            "question": "Is the condition of the bunding walls compliant with recognised standards",
            "question_id": "2.1.17"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Any internal drain must be sealed or bunded to ensure that no spillage or contaminated fire water gets into the surrounding environment.",
            "id": 80,
            "question": "If there is an internal drain, including WC, is it adequately bunded or sealed",
            "question_id": "2.1.18"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "If the height of the bund has changed, please note as a corrective action to allow the new capacity to be recorded accurately",
            "id": 81,
            "question": "What is the height of the bund in centimetres",
            "question_id": "2.1.19"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "If the area has changed, please note as a corrective action to allow the new capacity to be recorded accurately",
            "id": 82,
            "question": "What is the area of the bunded pesticide storage in metres squared",
            "question_id": "2.1.20"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "185%",
                    "failscore": 20
                },
                {
                    "answer": "110%",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "If other figure, please record in the corrective actions to allow BASIS to update our records.",
            "id": 83,
            "question": "What is the site sensitivity as designated by the Environment Agency",
            "question_id": "2.1.21"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "To calculate the FWR of the store = Height of the bund x store size (in sq. metres) x 1000.  e.g. 0.20 x 4.60 x 1000 = 920",
            "id": 84,
            "question": "What is the FWR/Spill volume of the store",
            "question_id": "2.1.22"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "To calculate the maximum stock allowed divide the FWR by the site sensitivity e.g. 920Lts / 1.1 = 836Lts max",
            "id": 85,
            "question": "What is the maximum amount of stock allowed in litres",
            "question_id": "2.1.23"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "",
            "id": 86,
            "question": "What was the stock level at the time of assessment",
            "question_id": "2.1.24"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, state stocking level as a corrective action.  Overstocked stores present a pollution risk and distributors must take immediate action to reduce the level to the maximum allowable.  If overstocking is a regular occurrence, the bund height should be increased.",
            "id": 87,
            "question": "Was the store overstocked",
            "question_id": "2.1.25"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Air Bricks",
                    "failscore": 20
                },
                {
                    "answer": "Louvres",
                    "failscore": 20
                },
                {
                    "answer": "Fans",
                    "failscore": 20
                },
                {
                    "answer": "Gaps",
                    "failscore": 20
                },
                {
                    "answer": "Vents",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "All stores should be adequately ventilated to ensure noxious or potentially flammable vapours can escape.  The need for additional ventilation should depend on a risk assessment based on a number of factors including possible spillage, leaking containers and good housekeeping involving the careful handling of pesticide packages.  Advice can be obtained from the Environmental Health Officer of the Health & Safety Executive.  Please see Code of Practice and the Health and Safety booklet HS (G)51 ‘The Storage of Flammable Liquids in Containers’.",
            "id": 88,
            "question": "Does the store have suitable ventilation",
            "question_id": "2.2.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Container stores should be ventilated to an external wall to ensure noxious vapours can escape.  Please see Code of Practice and the Health and Safety booklet HS (G)51 ‘The Storage of Flammable Liquids in Containers’.",
            "id": 89,
            "question": "If there is a walk-in container, is it vented to the outside of the container",
            "question_id": "2.2.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please detail under corrective actions.  The store should have natural or artificial light so that all product labels can be read easily at all times and to facilitate other working within the store.  Space should be allowed between artificial light sources and the top of stored products to ensure that heat is not transmitted.  Guidance on lighting in places of work is available from the Health and Safety Executive.  It is reasonable to assume that natural light will not be sufficient, especially at times of year when day length is short (e.g. winter).",
            "id": 90,
            "question": "Is the lighting in the store adequate for reading labels",
            "question_id": "2.3.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Fluorescent",
                    "failscore": 20
                },
                {
                    "answer": "Sodium",
                    "failscore": 20
                },
                {
                    "answer": "Bulkhead light",
                    "failscore": 20
                },
                {
                    "answer": "Natural",
                    "failscore": 20
                },
                {
                    "answer": "Spotlight",
                    "failscore": 20
                },
                {
                    "answer": "Torch",
                    "failscore": 20
                },
                {
                    "answer": "LED",
                    "failscore": 20
                },
                {
                    "answer": "Halogen",
                    "failscore": 20
                },
                {
                    "answer": "Other Suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 91,
            "question": "Describe the lighting in the store",
            "question_id": "2.3.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "By protected this could mean that the lighting is protected by a plastic or metal cover to stop glass from falling if the tube blows or prevent the light from being damaged or caught by a FLT.  Food industry standard tubes  (plastic sleeved) are more suitable for uncovered fluorescent lighting.  This is especially relevant to Seed Treatment plants and is only a recommendation for a standard store",
            "id": 92,
            "question": "Is the lighting in the store suitably protected where applicable",
            "question_id": "2.3.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, must be put in place.  Please raise a corrective action",
            "id": 93,
            "question": "Is there a BS5378 sign (Hazard Warning)",
            "question_id": "2.4.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 94,
            "question": "Is there a 999 sign",
            "question_id": "2.4.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No 999 Sign",
                    "failscore": 20
                },
                {
                    "answer": "Worn 999 Sign",
                    "failscore": 20
                },
                {
                    "answer": "No BS5378 Sign",
                    "failscore": 20
                },
                {
                    "answer": "Worn BS5378 Sign",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Signs must be put in place so they can be seen clearly from the outside approach to the store.",
            "id": 95,
            "question": "Are the signs displayed externally",
            "question_id": "2.4.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "The Dangerous Substances (Notification and Marking of Sites) Regulations 1990 (NAMOS) require written notification to be given to both the Fire Authority and Health & Safety Executive.  The Regulations apply to any site where a total quantity of 25 tonnes or more of dangerous substances may be stored now or at any time in the future. Signs should be visible from the approach to the store where appropriate.",
            "id": 96,
            "question": "Are NAMOS signs suitably positioned",
            "question_id": "2.4.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.  Guidance requires the prohibition sign to include flame as well as smoking.  This reference can be found on pages 29/30; 8.1.  This meaning is represented by a match in a barred circle.  It would be preferable to display this sign accompanied by the words ‘No Smoking or Naked Flames’ or ‘Smoking and Naked Flames Forbidden’.  The sign should be prominently displayed within the store, particularly at all entrances.",
            "id": 97,
            "question": "Is there a No Smoking or Naked Lights sign",
            "question_id": "2.4.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Fire fighting equipment, as recommended by the Fire Prevention Officer, must be provided and staff should be trained in its use.",
            "id": 98,
            "question": "Are the fire extinguishers in or adjacent to the store",
            "question_id": "2.5.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "1",
                    "failscore": 20
                },
                {
                    "answer": "2",
                    "failscore": 20
                },
                {
                    "answer": "3",
                    "failscore": 20
                },
                {
                    "answer": "4",
                    "failscore": 20
                },
                {
                    "answer": "5",
                    "failscore": 20
                },
                {
                    "answer": "6",
                    "failscore": 20
                },
                {
                    "answer": "7",
                    "failscore": 20
                },
                {
                    "answer": "8",
                    "failscore": 20
                },
                {
                    "answer": "9",
                    "failscore": 20
                },
                {
                    "answer": "10+",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 99,
            "question": "How many fire extinguishers are available in the store",
            "question_id": "2.5.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Foam",
                    "failscore": 20
                },
                {
                    "answer": "Water",
                    "failscore": 20
                },
                {
                    "answer": "CO2",
                    "failscore": 20
                },
                {
                    "answer": "Powder",
                    "failscore": 20
                },
                {
                    "answer": "Wet Chemical",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "The correct type of extinguisher should be available for the products stored.  Advice should be taken from the Fire Authority",
            "id": 100,
            "question": "What type of extinguishers are there",
            "question_id": "2.5.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Care must be taken to ensure the correct type of extinguisher is available.  E.g. If storing Aluminium Phosphide a water extinguisher cannot be used",
            "id": 101,
            "question": "Are the extinguishers suitable for the products stored",
            "question_id": "2.5.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Fire extinguishers should have a system of inspection by a competent person annually to ensure they are in a good usable condition.  A document or sticker should be attached to a fire extinguisher identifying the validation date of the extinguisher and when it is due for service/ inspection.",
            "id": 102,
            "question": "Are the fire extinguishers in good condition",
            "question_id": "2.5.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action unless not applicable due to disposal type of extinguisher.",
            "id": 103,
            "question": "Are the fire extinguishers serviced or inspected annually",
            "question_id": "2.5.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Please ensure that all fire extinguishers on site are individually serviced and dated.",
            "id": 104,
            "question": "When inspected were the fire extinguishers in date",
            "question_id": "2.5.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Signs should show the location of individual extinguishers, clearly visible within the store and, at each location, the type of extinguisher with details of the types of fire for which it is suitable.",
            "id": 105,
            "question": "Do the fire extinguisher signs comply",
            "question_id": "2.5.8"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "In order to comply with the minimum standards required by the Health & Safety at Work Act 1974, COSHH Regulations and the Industry guidance.  It is advisable to have a minimum of two 500ml bottles of eyewash, as the contents of a bottle should be used entirely in each specific incident.",
            "id": 106,
            "question": "Is there a minimum quantity of 2 x 500ml Eye Irrigator available for use in the store",
            "question_id": "2.6.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "DATE",
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Eyewash has a limited shelf life and the out of date bottles should be replaced with new stock as soon as possible.",
            "id": 107,
            "question": "Are the Eye Irrigators in date",
            "question_id": "5.6.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "2 x 500ml bottles of eyewash containing of either sterile water or sterile normal saline but in each case without biocide (preservative).",
            "id": 108,
            "question": "Are Eye Irrigators normal saline or sterile water",
            "question_id": "5.6.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Next to the door",
                    "failscore": 20
                },
                {
                    "answer": "Adjacent to the seed treatment area",
                    "failscore": 20
                },
                {
                    "answer": "In cupboard",
                    "failscore": 20
                },
                {
                    "answer": "Eyewash station",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable location",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 109,
            "question": "Where are the Eye Irrigators located",
            "question_id": "5.6.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In store by entrance",
                    "failscore": 20
                },
                {
                    "answer": "Outside store by entrance",
                    "failscore": 20
                },
                {
                    "answer": "Adjacent to the store",
                    "failscore": 20
                },
                {
                    "answer": "Personalised",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "PPE must be available in the store or very close to the store.",
            "id": 110,
            "question": "Location of PPE",
            "question_id": "2.6.5"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Lidded bin",
                    "failscore": 20
                },
                {
                    "answer": "Lidded box",
                    "failscore": 20
                },
                {
                    "answer": "Cupboard",
                    "failscore": 20
                },
                {
                    "answer": "Bag",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "If not, please raise a corrective action.  All items of personal protective equipment and any spare eyewash should be kept in a clearly marked bag, lidded dustbin or cupboard, keeping them clean and readily available.",
            "id": 111,
            "question": "How is the PPE stored",
            "question_id": "5.6.6"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Needs replacement",
                    "failscore": 20
                },
                {
                    "answer": "Needs cleaning",
                    "failscore": 20
                },
                {
                    "answer": "Needs storing in dust proof container",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  PPE will only be effective if it is used and maintained properly.",
            "id": 112,
            "question": "Is the PPE kit clean, serviceable and labelled",
            "question_id": "5.6.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, individual kits should be supplied and must be suitable for purpose, including being correctly matched to the job and wearer.",
            "id": 113,
            "question": "Is the PPE suitably sized for each operative in the store",
            "question_id": "5.6.8"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Face shield and brow guard missing",
                    "failscore": 30
                },
                {
                    "answer": "Rubber boots marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant apron marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant gloves marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant coverall missing",
                    "failscore": 30
                },
                {
                    "answer": "Goggles missing",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "If Yes please record as a corrective action.",
            "id": 114,
            "question": "Is there an item of PPE missing",
            "question_id": "2.6.9"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 115,
            "question": "Is all PPE CE marked",
            "question_id": "5.6.10"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  A half face respirator, marked CE, fully assembled, fitted with twin filter cartridges to give protection against organic gas/vapours/liquid particulates and dusts should be available.  The cartridges should be coded A1/P3 or ABEK/P3 marked CE and be in date.  The respirator should be fit tested to ensure a good fit.",
            "id": 116,
            "question": "Is there a respirator available",
            "question_id": "2.6.11"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "To ensure their effectiveness they must be in date.",
            "id": 117,
            "question": "Are the respirator filters in date",
            "question_id": "5.6.12"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "P3",
                    "failscore": 20
                },
                {
                    "answer": "A1",
                    "failscore": 20
                },
                {
                    "answer": "A1/P3",
                    "failscore": 20
                },
                {
                    "answer": "ABEK",
                    "failscore": 20
                },
                {
                    "answer": "ABEK1/3",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "Filters should be coded A1/P3 or ABEK/P3 and marked CE .",
            "id": 118,
            "question": "Is the filter type correct",
            "question_id": "5.6.13"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 119,
            "question": "Is there a washing facility for decontamination, outside or adjacent to the store",
            "question_id": "2.6.14"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In office",
                    "failscore": 20
                },
                {
                    "answer": "In mess room",
                    "failscore": 20
                },
                {
                    "answer": "In seed treatment area",
                    "failscore": 20
                },
                {
                    "answer": "In kitchen",
                    "failscore": 20
                },
                {
                    "answer": "In reception",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 1,
            "guidance": "The Health & Safety (First Aid) Regulations require employers to provide adequate first aid equipment.",
            "id": 120,
            "question": "Is there a first aid kit available",
            "question_id": "5.6.15"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Suitable Fist Aid signs should be installed as required by the Health & Safety (First Aid) Regulations.",
            "id": 121,
            "question": "Is there a location indicator for the first aid kit (First Aid sign)",
            "question_id": "5.6.16"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "The Health & Safety (First Aid) Regulations require employers to provide First Aid personnel, including, where appropriate, a trained First-aider.  The HSE can provide further information.",
            "id": 122,
            "question": "Is there a First Aider or Appointed Person",
            "question_id": "5.6.17"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Suitable Fist Aid signs showing the identity and location of the first aiders should be installed as required by the Health & Safety (First Aid) Regulations.",
            "id": 123,
            "question": "Is there a location indicator for the First Aider or Appointed Person",
            "question_id": "5.6.18"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Ensure that liquid products are not stored over dry’s.",
            "id": 124,
            "question": "Are wets separate from drys",
            "question_id": "2.7.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Herbicide Only store",
                    "failscore": 20
                },
                {
                    "answer": "Herbicides segregated",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Keep HERBICIDES segregated from fungicides, insecticides, growth regulators and other non herbicides e.g. slug pellets, to avoid cross- contamination.  This is equally applicable with dry herbicide products which often give off appreciable dust when being handled.",
            "id": 125,
            "question": "Are Herbicides segregated",
            "question_id": "2.7.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 126,
            "question": "Are Fungicides segregated",
            "question_id": "2.7.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 127,
            "question": "Are Plant Growth Regulators segregated",
            "question_id": "2.7.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 128,
            "question": "Are Slug Killers segregated",
            "question_id": "2.7.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "JET5 / Sorgene (disinfectant)",
                    "failscore": 20
                },
                {
                    "answer": "Others",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  OXIDISING AGENTS such as sodium chlorate should be kept within a separate metal, fire resistant, dry container, set apart from other pesticides where possible and within the bunded area.  Large quantities require a completely separate store.  ",
            "id": 129,
            "question": "Are Oxidising Agents segregated",
            "question_id": "2.7.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. Flammable pesticides (flash point 21-61˚C) should be located separately in their own section of the store, at the direction of the Fire Prevention Officer.  This area should be marked with a sign conforming to BS5378 i.e. flammable hazard warning sign (black flame in yellow and black triangle) Flammables should be stored away form Toxics and Corrosives.  In a very small store this may not be attainable.",
            "id": 130,
            "question": "Are flammables suitably stored",
            "question_id": "2.7.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. A sign conforming to BS5378 i.e. flammable hazard warning sign (black flame in yellow and black triangle) should be displayed.",
            "id": 131,
            "question": "If flammables are stored, is a suitable BS5499 sign displayed",
            "question_id": "2.7.8"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 132,
            "question": "Are nutrients segregated from pesticides",
            "question_id": "2.7.9"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 133,
            "question": "Do all products have MAFF / MAPP / HSE compliant labelling",
            "question_id": "2.7.10"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Non English label",
                    "failscore": 30
                },
                {
                    "answer": "Revoked product",
                    "failscore": 30
                },
                {
                    "answer": "Damaged label",
                    "failscore": 30
                },
                {
                    "answer": "In quarantine area awaiting collection",
                    "failscore": 30
                }
            ],
            "category_id": 1,
            "guidance": "If Yes, record. it is an offence under the Control of Pesticides Regulations 1986 to store products which are no longer approved.  Retention of these products risks prosecution.  It is recommended that they be disposed of as ‘toxic waste’.  If the products are in the quarantine area awaiting disposal the answer to the question is no.",
            "id": 134,
            "question": "Are any products in the store out of date / revoked and NOT held in the quarantine area",
            "question_id": "2.7.11"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, please record as a corrective action.",
            "id": 135,
            "question": "Are there products in the store to check",
            "question_id": "2.7.12"
        },
        {
            "ResponseType": "Primary Only ",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "Understanding how to identify a potential counterfeit product is important.  This is not an audit question and the Auditor should ensure that this is purely information seeking.  If the NSK is not aware, please advise them of how to identify them i.e. photocopied label, packaging not as expected etc",
            "id": 136,
            "question": "Is the storekeeper aware how to identify counterfeit products",
            "question_id": "2.7.13"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Pallets on floor",
                    "failscore": 20
                },
                {
                    "answer": "Boxes on floor",
                    "failscore": 20
                },
                {
                    "answer": "Non-absorbent shelving",
                    "failscore": 20
                },
                {
                    "answer": "Racking",
                    "failscore": 20
                },
                {
                    "answer": "Pallets on racking",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 137,
            "question": "Describe the storage system",
            "question_id": "2.7.14"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "Racking must be installed by a competent person / company. (Not shelving)",
            "id": 138,
            "question": "Is there racking",
            "question_id": "2.7.15"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "The racking must be inspected annually by a competent person.  A competent person would usually be someone with specific training to be able to provide a written report on the condition of the racking.",
            "id": 139,
            "question": "Is there a written inspection report relating to the racking provided by a competent person",
            "question_id": "2.7.16"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 140,
            "question": "Did the report identify any issues which needed to be resolved",
            "question_id": "2.7.17"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No non-pesticides",
                    "failscore": 20
                },
                {
                    "answer": "Yes, feeds",
                    "failscore": 20
                },
                {
                    "answer": "Yes, seeds",
                    "failscore": 20
                },
                {
                    "answer": "Yes, fertilisers",
                    "failscore": 20
                },
                {
                    "answer": "Yes, animal products",
                    "failscore": 20
                },
                {
                    "answer": "Yes, fuels",
                    "failscore": 20
                },
                {
                    "answer": "Yes, horticultural sundries",
                    "failscore": 20
                },
                {
                    "answer": "Yes, wetters",
                    "failscore": 20
                },
                {
                    "answer": "Yes, others",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "If Yes, please record as a corrective action.  Non-pesticide goods, particularly those capable of supporting combustion, should not be kept in the pesticide store.",
            "id": 141,
            "question": "Are there any non-pesticides in the store",
            "question_id": "2.8.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "Outside of BASIS remit - advice only.",
            "id": 142,
            "question": "Are pallets stored above head height wrapped and/ or are hard hats worn (advisory only)",
            "question_id": "2.8.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  The stacking of pesticides should follow the manufacturers’ recommendations.",
            "id": 143,
            "question": "Are manufacturers' stacking guidelines being followed",
            "question_id": "2.9.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  FLT operators must be certificated and re certificated every 5 years",
            "id": 144,
            "question": "Are operators of fork lift trucks trained to the required standard if applicable",
            "question_id": "2.9.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Daily",
                    "failscore": 20
                },
                {
                    "answer": "Weekly",
                    "failscore": 20
                },
                {
                    "answer": "Monthly",
                    "failscore": 20
                },
                {
                    "answer": "On-going",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Distributors should ensure that regular checks on products are carried out  ",
            "id": 145,
            "question": "Are regular checks conducted on products in store",
            "question_id": "2.10.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If yes, and NOT quarantined, please raise a corrective action",
            "id": 146,
            "question": "Is any stock damaged or leaking in the store (NOT in the quarantine area)",
            "question_id": "2.10.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Labels and Ties should be used in order to identify waste or damaged / leaking stock.  Some waste bags now have a write on section which can be used instead of the label - the bag must still be tied up though",
            "id": 147,
            "question": "Are there labels and ties for securing and identifying damaged stock",
            "question_id": "2.10.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. Whilst sand is acceptable as an inert absorbent material for use in containing any pesticide spillage, BASIS recommends that consideration should be given to replacing it with absorbent granules. The reason for this is that granules are far more absorbent and have the additional benefit of being much lighter than sand.  In the event of a pesticide leakage, the disposal costs for contaminated granules will be considerably less than for sand.",
            "id": 148,
            "question": "Is there inert absorbent available",
            "question_id": "2.10.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  A shovel for clearing up spillages is required.",
            "id": 149,
            "question": "Is there a shovel available",
            "question_id": "2.10.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Heavy duty bags",
                    "failscore": 20
                },
                {
                    "answer": "Plastic container",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Containers should be available for holding waste ",
            "id": 150,
            "question": "Are there Impermeable Containers available",
            "question_id": "2.10.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Soft Bristles are more effective for use in the store.",
            "id": 151,
            "question": "Is there a soft brush available",
            "question_id": "2.10.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A quarantine area should be available in which to hold pesticide waste prior to disposal / collection",
            "id": 152,
            "question": "Is there a quarantine area",
            "question_id": "5.7.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Leakers must be disposed of correctly.  If this is not done correctly - please raise a corrective action",
            "id": 153,
            "question": "Are leakers disposed of correctly",
            "question_id": "2.10.9"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Via a waste disposal contractor",
                    "failscore": 20
                },
                {
                    "answer": "Company is a licensed waste disposal contractor",
                    "failscore": 20
                },
                {
                    "answer": "Returned to central collection point",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. ",
            "id": 154,
            "question": "Is pesticide waste disposed of correctly",
            "question_id": "2.10.10"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. ",
            "id": 155,
            "question": "Are empty containers disposed of correctly",
            "question_id": "2.10.11"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "If No, do the company ensure that all drains and/or permeable areas must be protected from contamination by any fire water.  This should be recorded in the ECP.  If you have any concerns - please record as a corrective action",
            "id": 156,
            "question": "Can the site retain firewater",
            "question_id": "2.11.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Level",
                    "failscore": 20
                },
                {
                    "answer": "To containment area",
                    "failscore": 20
                },
                {
                    "answer": "Dished",
                    "failscore": 20
                },
                {
                    "answer": "Towards drains",
                    "failscore": 20
                },
                {
                    "answer": "Towards store",
                    "failscore": 20
                },
                {
                    "answer": "Towards rough ground",
                    "failscore": 20
                },
                {
                    "answer": "Away from the store",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 157,
            "question": "Indicate the slope of the ground",
            "question_id": "2.11.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Concrete",
                    "failscore": 20
                },
                {
                    "answer": "Tarmac",
                    "failscore": 20
                },
                {
                    "answer": "Bunded trays",
                    "failscore": 20
                },
                {
                    "answer": "Bunded area",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "It is recommended (endorsed by the Environment Agency) that the loading/unloading areas outside of the  store should be impermeable i.e. concrete to minimise the risk of pollution in the event of an accidental spillage.  However suitable bunded trays or similar may be used when loading / unloading on rough or permeable ground.",
            "id": 158,
            "question": "In the event of a spillage is the spill containable",
            "question_id": "2.12.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Drain covers",
                    "failscore": 20
                },
                {
                    "answer": "Sand bags",
                    "failscore": 20
                },
                {
                    "answer": "Spill kits available",
                    "failscore": 20
                },
                {
                    "answer": "Goes to tank",
                    "failscore": 20
                },
                {
                    "answer": "Bunded area",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 159,
            "question": "Are drains suitably protected from spillage",
            "question_id": "2.12.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "A Penstock valve is not a requirement for all sites but maybe on the recommendation from the EA on initial inspection of new sites",
            "id": 160,
            "question": "Is there a penstock valve",
            "question_id": "2.12.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 161,
            "question": "Is transport or distribution undertaken",
            "question_id": "3.1.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 1,
            "guidance": "",
            "id": 162,
            "question": "Is there a retained DGSA",
            "question_id": "3.1.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not required",
                    "failscore": 20
                },
                {
                    "answer": "Held on site",
                    "failscore": 20
                },
                {
                    "answer": "Held centrally",
                    "failscore": 20
                }
            ],
            "category_id": 3,
            "guidance": "The last year's DGSA report should be available for reference by the authorities but may not be on-site but should be available at short notice.",
            "id": 163,
            "question": "Was the last DGSA report available for reference",
            "question_id": "3.1.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "INTEGER",
            "category_id": 4,
            "guidance": "",
            "id": 164,
            "question": "Number of vehicles",
            "question_id": "4.1.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Under 3.5 tonne",
                    "failscore": 20
                },
                {
                    "answer": "Over 3.5 tonne",
                    "failscore": 20
                },
                {
                    "answer": "Curtain sides over 4.5 tonne",
                    "failscore": 20
                },
                {
                    "answer": "Over 7.5 tonne",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 165,
            "question": "Type(s) of vehicles",
            "question_id": "4.1.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 166,
            "question": "Record the registration number of the vehicle(s) inspected",
            "question_id": "4.1.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 167,
            "question": "If not seen, who provided the information",
            "question_id": "4.1.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Shrink wrapped pallets",
                    "failscore": 20
                },
                {
                    "answer": "Pesticides in covered containers",
                    "failscore": 20
                },
                {
                    "answer": "Pesticides only carried",
                    "failscore": 20
                },
                {
                    "answer": "Other acceptable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "Pesticide products MUST be segregated from other materials being carried.",
            "id": 168,
            "question": "Is segregation of pesticides from other products appropriately achieved",
            "question_id": "4.1.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Sealed bulkhead",
                    "failscore": 20
                },
                {
                    "answer": "Separate cab/body",
                    "failscore": 20
                },
                {
                    "answer": "Rigid sealed container",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Pesticides should always be carried on road vehicles where the driver is separated from the load, either by a separate cab or by a chemical and vapour impervious barrier (sealed bulkhead).  Where this cannot be achieved, the use of a vapour proof, sealed metal or strong sealed plastic or fibreglass reinforced container would be acceptable.",
            "id": 169,
            "question": "Is the driver protected",
            "question_id": "4.1.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If a car is usually unsuitable as pesticides are classed as dangerous goods and appropriate spill kit, secure segregation etc. must be considered.",
            "id": 170,
            "question": "Is a car used",
            "question_id": "4.1.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If a trailer is used - the security of the load must be considered. Sheeting and out of site is essential",
            "id": 171,
            "question": "Is there a trailer, plus lid/sheet",
            "question_id": "4.1.8"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 172,
            "question": "Is there a chemsafe / rigid sealed container",
            "question_id": "4.1.9"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 173,
            "question": "Did you examine vehicle barriers, car systems, trailers, containers",
            "question_id": "4.1.10"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 174,
            "question": "Are In-Scope loads despatched (ADR)",
            "question_id": "4.1.11"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 175,
            "question": "Are ADR requirements fully understood",
            "question_id": "4.1.12"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "All vehicles transporting concentrate pesticides on the road should have a suitable current ECP with national contact details if applicable",
            "id": 176,
            "question": "Is there a suitable Emergency Contingency Plan for transport",
            "question_id": "4.1.13"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  In order to comply with guidance, transport vehicles carrying pesticides on public roads should be provided with 2 x 500ml eye irrigators.",
            "id": 177,
            "question": "Are 2 x 500ml eye irrigators available on the vehicle",
            "question_id": "7.5.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Eye wash has a limited shelf life and needs to be within the use-by date.",
            "id": 178,
            "question": "Are the irrigators in date",
            "question_id": "4.2.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 179,
            "question": "Are the irrigators suitable",
            "question_id": "6.5.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In bag with PPE",
                    "failscore": 20
                },
                {
                    "answer": "In cab",
                    "failscore": 20
                },
                {
                    "answer": "In first aid box or bag",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "All personal protective equipment including eyewash must be kept in the driver’s cab.",
            "id": 180,
            "question": "Where are they kept",
            "question_id": "4.2.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Drivers must be provided with PPE for use in an emergency.",
            "id": 181,
            "question": "Is a full set of PPE kit available on the vehicle",
            "question_id": "4.2.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Face Shield missing",
                    "failscore": 30
                },
                {
                    "answer": "Goggles missing",
                    "failscore": 30
                },
                {
                    "answer": "Coverall missing",
                    "failscore": 30
                },
                {
                    "answer": "Apron missing",
                    "failscore": 30
                },
                {
                    "answer": "Rubber boots missing",
                    "failscore": 30
                },
                {
                    "answer": "Respirator missing",
                    "failscore": 30
                },
                {
                    "answer": "Filters missing",
                    "failscore": 30
                },
                {
                    "answer": "Filters out of date",
                    "failscore": 30
                },
                {
                    "answer": "Filters wrong type",
                    "failscore": 30
                },
                {
                    "answer": "Gloves missing",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, record as a corrective action.  PPE should be the same as for use in the store.",
            "id": 182,
            "question": "Are items missing from the PPE on the vehicle",
            "question_id": "4.2.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "PPE should be individually sized as required by the Health & Safety Regulations.",
            "id": 183,
            "question": "Are PPE personalised, individual kits",
            "question_id": "4.2.8"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In a bag in the cab",
                    "failscore": 20
                },
                {
                    "answer": "In a box in the cab",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 4,
            "guidance": "PPE should be kept in the driver's cab preferably in a holdall or lidded box to keep it clean.",
            "id": 184,
            "question": "Where is the PPE located",
            "question_id": "4.2.9"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 185,
            "question": "Are the correct quantity of fire extinguishers carried on the vehicle",
            "question_id": "7.6.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Extinguishers should be easily accessible to the vehicle crew and protected against the effects of weather.",
            "id": 186,
            "question": "Are they accessible",
            "question_id": "4.2.11"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Cab",
                    "failscore": 20
                },
                {
                    "answer": "Body",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 187,
            "question": "Are they appropriately located",
            "question_id": "4.2.12"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Extinguishers must be dated with the date of the NEXT inspection due or be before the use by date.",
            "id": 188,
            "question": "Are fire extinguishers within date, with the date of the next inspection displayed",
            "question_id": "4.2.13"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Inert absorbent granules must be carried on the vehicle for use in clearing up any spills.",
            "id": 189,
            "question": "Is there a supply of inert absorbent granules available on the vehicle",
            "question_id": "4.2.14"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A shovel is needed on the vehicle for clearing up the absorbent granules.",
            "id": 190,
            "question": "Is there a suitable shovel available on the vehicle",
            "question_id": "4.2.15"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A stiff bristled brush is recommended for brushing hard surfaces (e.g. roads) to clear up any absorbent granules used.",
            "id": 191,
            "question": "Is there a stiff brush available on the vehicle",
            "question_id": "4.2.16"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Containers are required for spillage waste or leakers.",
            "id": 192,
            "question": "Are there Impermeable Containers available on the vehicle",
            "question_id": "4.2.17"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "",
            "id": 193,
            "question": "Is there a hazard warning sign",
            "question_id": "7.6.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "only for ADR",
            "id": 194,
            "question": "Are orange plates displayed on the front and rear of the vehicle (if applicable)",
            "question_id": "4.2.19"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Driver Awareness",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 1",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 2",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 3",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 4",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 5",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 6",
                    "failscore": 20
                },
                {
                    "answer": "ADR Class 7",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.  ADR has changed the requirements for driver training.  All vehicles except those carrying packages under the load limit must have general training PLUS ADR certification.  Any vehicle carrying packaged dangerous goods UNDER the small load limit MUST have general training.  ",
            "id": 195,
            "question": "Have drivers received suitable training",
            "question_id": "4.3.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.  Administration and other staff dealing with in-scope ADR loads MUST have received general Dangerous Goods Awareness training.",
            "id": 196,
            "question": "Are additional drivers trained to the required standard",
            "question_id": "4.3.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 197,
            "question": "Has the Transport Security Video been seen by appropriate staff",
            "question_id": "4.3.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Some dangerous goods awareness training is required by Health & Safety legislation and by ADR, CDG regs.  BASIS NSK  are suitable as is in house dangerous goods awareness training, as long as it is recorded.",
            "id": 198,
            "question": "Have all appropriate staff handling dangerous goods received recorded Dangerous Goods Awareness training",
            "question_id": "4.3.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not applicable - end user",
                    "failscore": 20
                },
                {
                    "answer": "Signed for by customer",
                    "failscore": 20
                },
                {
                    "answer": "Key location known",
                    "failscore": 20
                },
                {
                    "answer": "Secure store",
                    "failscore": 20
                },
                {
                    "answer": "Handed over",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 199,
            "question": "How are goods off-loaded at end user",
            "question_id": "4.4.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Integral",
                    "failscore": 20
                },
                {
                    "answer": "Remote",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 200,
            "question": "Is the seed treatment area integral with or remote from the pesticide store",
            "question_id": "5.1.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If it is remote, please inspect the store.",
            "id": 201,
            "question": "If it is remote from the seed treatment operation, have you already inspected the store",
            "question_id": "5.1.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Secure site",
                    "failscore": 20
                },
                {
                    "answer": "Alarmed",
                    "failscore": 20
                },
                {
                    "answer": "Locked",
                    "failscore": 20
                },
                {
                    "answer": "Alarmed and locked",
                    "failscore": 20
                },
                {
                    "answer": "Security lights, locked store",
                    "failscore": 20
                },
                {
                    "answer": "CCTV",
                    "failscore": 20
                },
                {
                    "answer": "Other approved",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 202,
            "question": "Is the seed treatment area secure",
            "question_id": "5.2.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 203,
            "question": "Are the in-use seed treatment pesticides secure",
            "question_id": "5.2.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "External",
                    "failscore": 20
                },
                {
                    "answer": "Internal",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 3,
            "guidance": "",
            "id": 204,
            "question": "Is the ventilation in the seed treatment area suitable",
            "question_id": "5.3.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Sodium",
                    "failscore": 20
                },
                {
                    "answer": "Florescent",
                    "failscore": 20
                },
                {
                    "answer": "Spotlights",
                    "failscore": 20
                },
                {
                    "answer": "Natural",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 3,
            "guidance": "",
            "id": 205,
            "question": "Is the lighting in the seed treatment area suitable and protected (if applicable)",
            "question_id": "5.4.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Whole area bunded",
                    "failscore": 20
                },
                {
                    "answer": "Bunded pallets",
                    "failscore": 20
                },
                {
                    "answer": "Bunded sumps",
                    "failscore": 20
                },
                {
                    "answer": "Trays used",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 206,
            "question": "Are 'in-use' pesticides suitably contained in the seed treatment area",
            "question_id": "5.5.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "",
            "id": 207,
            "question": "What is the area of the bund in metres squared",
            "question_id": "5.5.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "If No, are bunded metal or plastic trays used to contain any spillage.",
            "id": 208,
            "question": "What height is the bunded area (cm)",
            "question_id": "5.5.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "INTEGER",
            "category_id": 4,
            "guidance": "",
            "id": 209,
            "question": "What is the Fire Water Retention / Spillage volume of the bunded area in litres",
            "question_id": "5.5.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Worn floor",
                    "failscore": 20
                },
                {
                    "answer": "Cracked floor",
                    "failscore": 20
                },
                {
                    "answer": "Damaged floor",
                    "failscore": 20
                },
                {
                    "answer": "Worn walls",
                    "failscore": 20
                },
                {
                    "answer": "Cracked walls",
                    "failscore": 20
                },
                {
                    "answer": "Damaged walls",
                    "failscore": 20
                },
                {
                    "answer": "Other, please describe as an observation",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 3,
            "guidance": "The bunded area may refer to the whole building being bunded or the use of bunded trays where appropriate",
            "id": 210,
            "question": "Is the bunded area in sound condition",
            "question_id": "5.5.5"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 211,
            "question": "Are there pits present in the Seed Treatment area",
            "question_id": "5.5.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Pits are the lowest point of a seed treatment site.  Any spills or fire water would run to this area in the event of an incident so the pits do need to be impermeable.",
            "id": 212,
            "question": "Are the pits impermeable",
            "question_id": "5.5.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "In order to comply with the minimum standards required by the Health & Safety at Work Act 1974, COSHH Regulations and current guidance, it is advisable to have a minimum of two 500 ml bottles of eyewash, as the contents of a bottle should be used entirely in each specific incident.",
            "id": 213,
            "question": "Is there a minimum quantity of 2 x 500ml Eye Irrigator available in the seed treatment area",
            "question_id": "5.6.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "In seed treatment area by entrance",
                    "failscore": 20
                },
                {
                    "answer": "Outside seed treatment area by entrance",
                    "failscore": 20
                },
                {
                    "answer": "Adjacent to the seed treatment area",
                    "failscore": 20
                },
                {
                    "answer": "Personalised",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "PPE must be available in the store or very close to the store.",
            "id": 214,
            "question": "Where is PPE located for the seed treatment area",
            "question_id": "5.6.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Face shield and brow guard missing",
                    "failscore": 30
                },
                {
                    "answer": "Rubber boots marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant apron marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant gloves marked CE missing",
                    "failscore": 30
                },
                {
                    "answer": "Chemical resistant coverall missing",
                    "failscore": 30
                },
                {
                    "answer": "Goggles missing",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, please raise a corrective action.",
            "id": 215,
            "question": "Is there an item missing from the PPE in the seed treatment area",
            "question_id": "5.6.9"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  A half face respirator, marked CE, fully assembled, fitted with twin filter cartridges to give protection against organic gas/vapours/liquid particulates and dusts should be available.  The cartridges should be coded A1/P3 or ABEK/P3 marked CE and be in date. The respirator must be fit tested to the operator.",
            "id": 216,
            "question": "Is there a respirator available in the seed treatment area",
            "question_id": "5.6.11"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 217,
            "question": "Is there a washing facility for decontamination, outside or adjacent to the area",
            "question_id": "5.6.14"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Inert absorbent should be available for soaking up spills ",
            "id": 218,
            "question": "Is there inert absorbent available for the seed treatment area",
            "question_id": "5.7.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A shovel should be available for clearing up spillage / absorbent",
            "id": 219,
            "question": "Is there a shovel",
            "question_id": "5.7.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Impermeable containers should be available in which to put leakers or waste pending disposal",
            "id": 220,
            "question": "Are there Impermeable Containers",
            "question_id": "5.7.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A soft brush is required for clearing up absorbent etc.",
            "id": 221,
            "question": "Is there a soft brush",
            "question_id": "5.7.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Labels and ties or suitable 'write on' bags should be available to identify the different types of waste as this could be over treated grain or spillage / leakers",
            "id": 222,
            "question": "Are labels and ties available",
            "question_id": "5.7.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "The prohibition sign should include flame as well as smoking.  This meaning is represented by a match in a barred circle.  It would be preferable to display this sign accompanied by the words ‘No Smoking or Naked Flames’ or ‘Smoking and Naked Flames Forbidden’.  The sign should be prominently displayed within the store, particularly at all entrances.",
            "id": 223,
            "question": "Is there a No Smoking / Naked Lights sign",
            "question_id": "5.8.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Foam",
                    "failscore": 20
                },
                {
                    "answer": "Water",
                    "failscore": 20
                },
                {
                    "answer": "CO2",
                    "failscore": 20
                },
                {
                    "answer": "Powder",
                    "failscore": 20
                },
                {
                    "answer": "Wet Chemical",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 224,
            "question": "Are there suitable in-date fire extinguishers available in the seed treatment area",
            "question_id": "5.9.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Fire extinguishers must be serviced annually and dated.",
            "id": 225,
            "question": "Are the extinguishers serviced or inspected annually",
            "question_id": "5.9.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Signs should show the location of individual extinguishers, clearly visible within the store and, at each location, the type of extinguisher with details of the types of fire for which it is suitable.",
            "id": 226,
            "question": "Do extinguisher signs comply",
            "question_id": "5.9.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "In order to comply with the Health and Safety at Work Act 1974, please ensure that seed treatment plant operators’ full safety precautions are available, close to the seed treaters.  These are available from the machine manufacturer in the form of a wall chart or as a manual. At the very least the safety precautions should be available on site for reference.",
            "id": 227,
            "question": "Are there Operator Safety Precautions",
            "question_id": "5.10.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "On the machine",
                    "failscore": 20
                },
                {
                    "answer": "In the office",
                    "failscore": 20
                },
                {
                    "answer": "In the Mess Room",
                    "failscore": 20
                },
                {
                    "answer": "Other location",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 3,
            "guidance": "",
            "id": 228,
            "question": "Are Operator Safety Precautions displayed or available",
            "question_id": "5.10.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "On the machine",
                    "failscore": 20
                },
                {
                    "answer": "In the office",
                    "failscore": 20
                },
                {
                    "answer": "In the Mess Room",
                    "failscore": 20
                },
                {
                    "answer": "Other location",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 229,
            "question": "Is the Operators' Handbook available for the machinery",
            "question_id": "5.10.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Operator Certificate of Competence",
                    "failscore": 20
                },
                {
                    "answer": "City & Guilds (NPTC)",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "Operators should be trained to City & Guilds standard and appropriately certificated to PA1 and PA 11 standard",
            "id": 230,
            "question": "Is there a suitably certificated operator  (Certificate of Competence/City & Guilds (NPTC))",
            "question_id": "5.10.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 231,
            "question": "Is a forked lift truck used in the seed treatment area",
            "question_id": "5.11.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Operators must be certificated and refresher trained every 5 years",
            "id": 232,
            "question": "Are forked lift truck drivers trained to an appropriate standard",
            "question_id": "5.11.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "Although subjective, the area should be a safe working environment.",
            "id": 233,
            "question": "Is the treatment area clean and tidy",
            "question_id": "5.11.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "",
            "id": 234,
            "question": "If there is a store, is it registered under Part II",
            "question_id": "7.1.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 235,
            "question": "When using approved pesticides under COSHH / Plant Protection Products (Sustainable Use) 2012 Regulations, are records kept of products used",
            "question_id": "6.2.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.",
            "id": 236,
            "question": "Are records kept for all applications",
            "question_id": "7.2.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 237,
            "question": "Are records kept for three years",
            "question_id": "7.2.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Health & Safety and COSHH Regulations do require Health surveillance for operators if they are using noisy equipment i.e. ST plant - routine blood tests are often required when using certain products.  It is up to the company to ensure they meet their obligations",
            "id": 238,
            "question": "Is there health surveillance for Operators as required by COSHH Regulations (if appropriate)",
            "question_id": "6.2.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If no, please raise a corrective action.  The Risk Assessment must be completed and specific to each operation",
            "id": 239,
            "question": "Is there a suitable Risk Assessment for this operation",
            "question_id": "6.2.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action. The Health & Safety (First Aid) Regulations require employers to provide First Aid personnel, including, where appropriate, a trained First-aider. The HSE can provide further information.  A first aid risk assessment will determine the level of training and number of persons required to be trained.  BASIS does not inspect the First aid risk assessment",
            "id": 240,
            "question": "Are Operators suitably instructed in First Aid according to their risk assessment",
            "question_id": "7.2.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If no, please raise a corrective action.  An Emergency Action plan should be available to include national contact numbers etc. to allow the operator to contact the relevant organisations in the event of an incident",
            "id": 241,
            "question": "Is a suitable Emergency Contingency Plan available which covers the mobile operation",
            "question_id": "6.2.7"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "1",
                    "failscore": 20
                },
                {
                    "answer": "2",
                    "failscore": 20
                },
                {
                    "answer": "3",
                    "failscore": 20
                },
                {
                    "answer": "4",
                    "failscore": 20
                },
                {
                    "answer": "5",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 242,
            "question": "How many Mobile seed treaters are there",
            "question_id": "6.3.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "",
            "id": 243,
            "question": "How much seed treatment pesticide was in use at time of assessment",
            "question_id": "6.3.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Bunded trays",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable bunded containment",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 244,
            "question": "Are containment facilities of 'in-use' pesticides suitable",
            "question_id": "7.3.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "",
            "id": 245,
            "question": "What is the spill volume of containment areas",
            "question_id": "6.3.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If Yes, please note that the mobile becomes a store.",
            "id": 246,
            "question": "Are pesticides kept on application/support vehicles for more than 24 hours",
            "question_id": "7.3.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 247,
            "question": "If the mobile seed treater is classed as a store, is it compliant with storage requirements",
            "question_id": "6.3.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.",
            "id": 248,
            "question": "Are pesticide containers on the mobile seed treater restricted from the public when in use",
            "question_id": "6.3.7"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "PA1",
                    "failscore": 20
                },
                {
                    "answer": "PA2",
                    "failscore": 20
                },
                {
                    "answer": "PA6",
                    "failscore": 20
                },
                {
                    "answer": "Other suitable PA",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 249,
            "question": "Are operators certificated to City & Guilds standard",
            "question_id": "7.4.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.",
            "id": 250,
            "question": "Is the 'Code of Practice for Using Plant Protection Products' available for reference and adhered to",
            "question_id": "7.4.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  In order to comply with the guidance, transport vehicles carrying pesticides on public roads should be provided with 2 x 500ml eye irrigators.",
            "id": 251,
            "question": "Are 2 x 500ml eye irrigators available on the treatment vehicle",
            "question_id": "6.5.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "DATE",
            "category_id": 2,
            "guidance": "If No, please raise a corrective action.  Eye wash has a limited shelf life and needs to be within the use-by date.",
            "id": 252,
            "question": "Are the eye irrigators in date",
            "question_id": "7.5.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Face shield and brow guard missing",
                    "failscore": 20
                },
                {
                    "answer": "Rubber boots marked CE missing",
                    "failscore": 20
                },
                {
                    "answer": "Chemical resistant apron marked CE missing",
                    "failscore": 20
                },
                {
                    "answer": "Chemical resistant gloves marked CE missing",
                    "failscore": 20
                },
                {
                    "answer": "Chemical resistant coverall missing",
                    "failscore": 20
                },
                {
                    "answer": "Goggles missing",
                    "failscore": 20
                },
                {
                    "answer": "Dust mask / respirator, if applicable",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 253,
            "question": "Is appropriate PPE available, as per product label requirements",
            "question_id": "7.5.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Disposable",
                    "failscore": 20
                },
                {
                    "answer": "Industrial laundry",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Please note that any contaminated PPE must be disposed of as toxic waste via a licensed waste disposal contractor or laundered through an industrial laundry.",
            "id": 254,
            "question": "Is PPE suitably decontaminated",
            "question_id": "7.5.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.  The main tank on applicators should not be used as this may be contaminated.  A separate supply of clean water is required.",
            "id": 255,
            "question": "Is there a supply of clean washing water for emergency",
            "question_id": "6.5.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.  Bottled mineral water is fine for this purpose.",
            "id": 256,
            "question": "Is labelled drinking water available for rinsing the mouth in an emergency",
            "question_id": "7.5.7"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Sealed bulkhead",
                    "failscore": 20
                },
                {
                    "answer": "Separate cab/body",
                    "failscore": 20
                },
                {
                    "answer": "Vapour-proof barrier",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If no, please raise a corrective action.  Pesticides should always be carried on road vehicles where the driver is separated from the load, either by a separate cab or by a chemical and vapour impervious barrier (sealed bulkhead).  Where this cannot be achieved, the use of a vapour proof, sealed metal or strong sealed plastic or fibreglass reinforced container would be acceptable.",
            "id": 257,
            "question": "Is the driver protected from pesticides",
            "question_id": "7.6.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 258,
            "question": "Is the correct quantity of fire extinguishers carried",
            "question_id": "6.6.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 259,
            "question": "Are fire extinguishers within date, with the date displayed",
            "question_id": "7.6.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Absorbent granules",
                    "failscore": 30
                },
                {
                    "answer": "Shovel",
                    "failscore": 30
                },
                {
                    "answer": "Stiff brush",
                    "failscore": 30
                },
                {
                    "answer": "Impermeable containers",
                    "failscore": 30
                },
                {
                    "answer": "Containment bin",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, please record as a corrective action.  All safety equipment should be as for any vehicle transporting dangerous goods.",
            "id": 260,
            "question": "Are safety items missing from the vehicle or treater",
            "question_id": "6.6.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Instructions in Writing MUST be carried (ADR).",
            "id": 261,
            "question": "Are Instructions In Writing available if applicable",
            "question_id": "6.6.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Registered waste disposal contractor",
                    "failscore": 20
                },
                {
                    "answer": "Returned to manufacturer",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 262,
            "question": "Are empty packs disposed of correctly",
            "question_id": "7.7.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If No, please raise a corrective action.",
            "id": 263,
            "question": "When using approved pesticides under COSHH/Plant Protection Products (Sustainable Use) 2012 Regulations, are records kept of products issued from store",
            "question_id": "7.2.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Health & Safety and COSHH Regulations do require Health surveillance for operators if they are using noisy equipment i.e. ST plant - routine blood tests are often required when using certain products.  It is up to the company to ensure they meet their obligations",
            "id": 264,
            "question": "Is there health surveillance for Operators as required by COSHH Regulations (were applicable)",
            "question_id": "7.2.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "If no, please raise a corrective action.  A risk assessment must be completed for each site - a generic one is not considered suitable as the risks on each site are often varied e.g. Schools, parks etc.",
            "id": 265,
            "question": "Is there a suitable and sufficient Risk Assessment for this operation",
            "question_id": "7.2.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If no, please raise a corrective action.  An Emergency Action plan should be available to include national contact numbers etc. to allow the operator to contact the relevant organisations in the event of an incident.",
            "id": 266,
            "question": "Is a suitable Emergency Contingency Plan available",
            "question_id": "7.2.7"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "1",
                    "failscore": 20
                },
                {
                    "answer": "2",
                    "failscore": 20
                },
                {
                    "answer": "3",
                    "failscore": 20
                },
                {
                    "answer": "4",
                    "failscore": 20
                },
                {
                    "answer": "5+ (record number as observation)",
                    "failscore": 20
                },
                {
                    "answer": "Knapsack only",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 267,
            "question": "How many application vehicle(s) are there",
            "question_id": "7.3.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Tractor mounted",
                    "failscore": 20
                },
                {
                    "answer": "ATV",
                    "failscore": 20
                },
                {
                    "answer": "Self-propelled",
                    "failscore": 20
                },
                {
                    "answer": "Knapsack only",
                    "failscore": 20
                },
                {
                    "answer": "Other",
                    "failscore": 20,
                    "subquestion": [
                        2
                    ]
                },
                {
                    "answer": "Not Seen at time of assessment",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 268,
            "question": "What type of application equipment was seen",
            "question_id": "7.3.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "FLOAT",
            "category_id": 4,
            "guidance": "",
            "id": 269,
            "question": "How much pesticide was in use at time of assessment",
            "question_id": "7.3.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "FLOAT",
            "category_id": 2,
            "guidance": "",
            "id": 270,
            "question": "Is the FWR/spill volume of containment area of suitable capacity",
            "question_id": "7.3.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 271,
            "question": "If the vehicle is classed as a store, is it compliant with storage requirements",
            "question_id": "7.3.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.",
            "id": 272,
            "question": "Are pesticide containers secure from the public when in use",
            "question_id": "7.3.8"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 273,
            "question": "Are the eye irrigators suitable",
            "question_id": "7.5.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "If No, please raise a corrective action.  The main tank on applicators should not be used as this may be contaminated.  A separate supply of clean water is required.",
            "id": 274,
            "question": "Is there a supply of clean washing water for emergency available on the vehicle",
            "question_id": "7.5.6"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Absorbent granules",
                    "failscore": 30
                },
                {
                    "answer": "Shovel",
                    "failscore": 30
                },
                {
                    "answer": "Stiff brush",
                    "failscore": 30
                },
                {
                    "answer": "Impermeable containers",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "If Yes, record.  All safety equipment should be as for any vehicle transporting dangerous goods.",
            "id": 275,
            "question": "Are safety items missing from the vehicle",
            "question_id": "7.6.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 276,
            "question": "Are Instructions In Writing available if required",
            "question_id": "7.6.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Used on site",
                    "failscore": 20
                },
                {
                    "answer": "Taken to another job",
                    "failscore": 20
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 277,
            "question": "Are surplus pesticide/washings disposed of correctly",
            "question_id": "7.7.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "If no, please raise a corrective action.  All empty packs should be triple rinsed before being disposed of.",
            "id": 278,
            "question": "Are empty packs triple rinsed",
            "question_id": "7.7.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Contractors should have an Environmental Management Policy",
            "id": 279,
            "question": "Does the Amenity contractor have a written Environmental Management Policy statement",
            "question_id": "8.1.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "Yes - ISO 9001",
                    "failscore": 20
                },
                {
                    "answer": "Yes - ISO 14001",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 280,
            "question": "Has ISO accreditation been achieved",
            "question_id": "8.1.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 281,
            "question": "Is the company registered with the Contractors' Health and Safety Scheme",
            "question_id": "8.1.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Company is a licensed waste disposal contractor",
                    "failscore": 20
                },
                {
                    "answer": "External licensed waste disposal contractor",
                    "failscore": 20
                },
                {
                    "answer": "Returned to manufacturer",
                    "failscore": 20
                },
                {
                    "answer": "Returned to central collection point",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 282,
            "question": "Does this contractor have arrangements for waste disposal",
            "question_id": "8.1.4"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Missing information",
                    "failscore": 20
                },
                {
                    "answer": "No disposal receipts kept",
                    "failscore": 20
                },
                {
                    "answer": "No records kept",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Full records of all waste must be kept",
            "id": 283,
            "question": "Are concise records of waste disposal kept",
            "question_id": "8.1.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "All contracting operations should also have a specific ECP for those operations covering national / local issues",
            "id": 284,
            "question": "Is there an Emergency Contingency Plan available for the contracting operations",
            "question_id": "8.1.6"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "TEXT",
            "category_id": 1,
            "guidance": "Recommendations can only be made by a certificated advisor",
            "id": 285,
            "question": "Are sales / advisory staff BASIS certificated",
            "question_id": "8.2.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "3 months",
                    "failscore": 20
                },
                {
                    "answer": "6 months",
                    "failscore": 20
                },
                {
                    "answer": "9 months",
                    "failscore": 20
                },
                {
                    "answer": "12 months",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 286,
            "question": "Are new staff trained to suitable City & Guilds standards within an acceptable timeframe",
            "question_id": "8.3.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "",
            "id": 287,
            "question": "Are new employees always supervised and within sight and sound of a suitably qualified person while training and until C&G certification is awarded",
            "question_id": "8.3.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 20
                },
                {
                    "answer": "No",
                    "failscore": 20
                },
                {
                    "answer": "N/A",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "If No, please raise a corrective action.",
            "id": 288,
            "question": "Are company ID cards issued to all employees",
            "question_id": "8.3.3"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Routine updates",
                    "failscore": 20
                },
                {
                    "answer": "Annual pre-season briefings",
                    "failscore": 20
                },
                {
                    "answer": "Continual toolbox talks",
                    "failscore": 20
                }
            ],
            "category_id": 3,
            "guidance": "Regular training to keep operative up to date is strongly recommended",
            "id": 289,
            "question": "Is training updated",
            "question_id": "8.3.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 290,
            "question": "How many PA qualified staff do you employ",
            "question_id": "8.3.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 3,
            "guidance": "Records showing training should be kept",
            "id": 291,
            "question": "Are training records kept for all staff",
            "question_id": "8.3.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 292,
            "question": "Are written quotations issued (if applicable)",
            "question_id": "8.4.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "Records should be kept for all contracts on file",
            "id": 293,
            "question": "Are details of contracts held",
            "question_id": "8.4.2"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Signed off by client",
                    "failscore": 20
                },
                {
                    "answer": "Each spray/treatment signed off by client",
                    "failscore": 20
                },
                {
                    "answer": "Inspected by client",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 294,
            "question": "Are systems in place to ensure completion of contracts",
            "question_id": "8.4.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "",
            "id": 295,
            "question": "Are individual job records completed for all chemical applications",
            "question_id": "8.4.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 1,
            "guidance": "Records should be kept for a period of three years",
            "id": 296,
            "question": "Are all records retained for a minimum of three years",
            "question_id": "8.4.5"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Date of complaint",
                    "failscore": 20
                },
                {
                    "answer": "Date of incidence or occurrence",
                    "failscore": 20
                },
                {
                    "answer": "Location",
                    "failscore": 20
                },
                {
                    "answer": "A description of the incident",
                    "failscore": 20
                },
                {
                    "answer": "Suitable response time from date recorded",
                    "failscore": 20
                },
                {
                    "answer": "Report completed",
                    "failscore": 20
                },
                {
                    "answer": "Recommendations signed off",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "All complaints should be thoroughly recorded and investigated and a response provided to the complainant",
            "id": 297,
            "question": "Is there a full complaints recording system",
            "question_id": "8.5.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Pass",
                    "failscore": 20
                },
                {
                    "answer": "Pass Noted",
                    "failscore": 20
                },
                {
                    "answer": "Qualified Pass",
                    "failscore": 20
                },
                {
                    "answer": "Fail",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "Please assign a grade as this is an indication of your opinion of the audit.  The grade may change dependent on other information which may be available at HO",
            "id": 298,
            "question": "What grade do you feel that this audit has attained",
            "question_id": "11.4.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TIME",
            "category_id": 4,
            "guidance": "",
            "id": 299,
            "question": "What time was the Inspection Scheme audit completed",
            "question_id": "9.2.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "EA approval must be valid for each site.  Evidence to support this is usually a letter of approval which lasts 5 years or an Environmental Risk Assessment",
            "id": 300,
            "question": "Have you attached evidence of EA approval",
            "question_id": "10.1.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "EA risk assessments are essential to compliance. Please ensure this is completed to the best of your ability and returned with your audit.",
            "id": 301,
            "question": "Have you attached evidence of  an EA Risk Assessment if applicable",
            "question_id": "10.1.2"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "Fire Approval must be valid for each site.  Evidence to support this is usually a letter of approval which lasts 5 years",
            "id": 302,
            "question": "Have you attached evidence of Fire Authority approval",
            "question_id": "10.1.3"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "A written Fire risk assessment is a requirement for all businesses that employ staff.  This should be reviewed annually.  Please attach evidence of annual review",
            "id": 303,
            "question": "Have you attached evidence of a current Fire Risk Assessment",
            "question_id": "10.1.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "An Emergency Contingency plan should be reviewed annually.  Please attach evidence of annual review",
            "id": 304,
            "question": "Have you attached evidence of an Emergency Contingency plan review",
            "question_id": "10.1.5"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "A Health & Safety Policy or statement should be reviewed on a regular basis.  It is recommended that this is annual.  Please attach evidence of annual review",
            "id": 305,
            "question": "Have you attached evidence of a Health & Safety Policy review",
            "question_id": "10.1.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 10
                },
                {
                    "answer": "No",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "CoSHH assessments should be reviewed regularly.  It is recommended that this is conducted annually.  Please attach evidence of annual review",
            "id": 306,
            "question": "Have you attached evidence of a COSHH Assessment review",
            "question_id": "10.1.7"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 307,
            "question": "Who completed this Self-Audit",
            "question_id": "10.1.8"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "Please remember that this is purely a Point Of Sale audit.  We are not looking at storage or certification of the products held at the site.",
            "id": 308,
            "question": "Who is the person responsible at POS",
            "question_id": "11.0.1"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 309,
            "question": "What is their contact telephone number",
            "question_id": "11.0.2"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 310,
            "question": "What is their email address",
            "question_id": "11.0.3"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TEXT",
            "category_id": 4,
            "guidance": "",
            "id": 311,
            "question": "Who was the audit conducted with",
            "question_id": "11.0.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 312,
            "question": "Are Sales made to Certificated Users",
            "question_id": "11.1.1"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Unknown",
                    "failscore": 20
                },
                {
                    "answer": "Phone Transaction",
                    "failscore": 20
                },
                {
                    "answer": "In Person - Over the counter",
                    "failscore": 20
                },
                {
                    "answer": "Email /post",
                    "failscore": 20
                },
                {
                    "answer": "Online",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "If the type of sale is identifiable please note.",
            "id": 313,
            "question": "Evidence of transaction to the user seen and types of sales",
            "question_id": "11.1.2"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "ID should be seen not necessarily a copy taken (Data Protection)",
            "id": 314,
            "question": "Proof of ID for new customers",
            "question_id": "11.1.3"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "RSPH/BPCA Level 2 Award in Pest Management (2010 onwards)",
                    "failscore": 20
                },
                {
                    "answer": "RSPH/BPCA Level 2 Certificate in Pest Management (2010 onwards)",
                    "failscore": 20
                },
                {
                    "answer": "City & Guilds NPTC Level 2 Award in the Safe Use of Pesticides for Vertebrate pest Control for Rats and Mice (QCF) (PAR&M) (2013 onwards)",
                    "failscore": 20
                },
                {
                    "answer": "Lantra: Responsible and Effective Control of Commensal Rodents (2016 onwards) online: elearning.lantra.co.uk",
                    "failscore": 20
                },
                {
                    "answer": "Lantra: Rodent Control on Farms (2015 onwards) * online; elearning.lantra.co.uk",
                    "failscore": 20
                },
                {
                    "answer": "Rat Control for Gamekeepers (2015 onwards) through BASIS",
                    "failscore": 20
                },
                {
                    "answer": "Killgerm Principles of Rodent Control (2016 onwards through BASIS)",
                    "failscore": 20
                },
                {
                    "answer": "RSPH Level 2 Award in the Safe Use Of Rodenticides (2015 onwards)",
                    "failscore": 20
                },
                {
                    "answer": "BPCA Using Rodenticides Safely (2015 onwards, exam through BASIS)",
                    "failscore": 20
                },
                {
                    "answer": "RSPH Level 3 Diploma in Pest Management (201082016)",
                    "failscore": 20
                },
                {
                    "answer": "RSPH/BPCA Level 2 Certificate in Pest Control (2004-2010)",
                    "failscore": 20
                },
                {
                    "answer": "RSPH Level 2 Certificate in Pest Control (2000-2004)",
                    "failscore": 20
                },
                {
                    "answer": "RSH Certificate in Pest Control (pre-2000 inclusive)",
                    "failscore": 20
                },
                {
                    "answer": "BPCA Diploma in Pest Control Part 1 (See guidance for details of previous names)",
                    "failscore": 20
                },
                {
                    "answer": "NPTC Level 2 Certificate of Competence in Vertebrate Pest Control (assessed in the context of rats and mice) (2002-2014)",
                    "failscore": 20
                },
                {
                    "answer": "Lantra Rodent Control (previously Rat and Mouse Control) (2009-2015)",
                    "failscore": 20
                },
                {
                    "answer": "Lantra Rodent Control on Farms (2015- 28/02/2018 inclusive)",
                    "failscore": 20
                },
                {
                    "answer": "Killgerm Principles of Rodent Control (previously Killgerm Rodent Biology and Control) (2004-2015)",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Only the certificates and grandfather certificates listed are accepted by CRRU as suitable for use.  The current approved list is on the CRRU website and should be checked",
            "id": 315,
            "question": "Proof of approved certification checked",
            "question_id": "11.1.4"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Declaration compliant",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not available",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not signed",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show address of the farm or alternative delivery address",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show name of purchaser /collector",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Declarations should be completed and persons identified as authorised to collect on behalf of the user.  Delivery addresses should also all be listed",
            "id": 316,
            "question": "Is there a Certificated Users Declaration",
            "question_id": "11.1.5"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "N/A",
                    "failscore": 20
                },
                {
                    "answer": "1",
                    "failscore": 20
                },
                {
                    "answer": "2",
                    "failscore": 20
                },
                {
                    "answer": "3",
                    "failscore": 20
                },
                {
                    "answer": "4",
                    "failscore": 20
                },
                {
                    "answer": "5",
                    "failscore": 20
                },
                {
                    "answer": "6",
                    "failscore": 20
                }
            ],
            "category_id": 4,
            "guidance": "Please check around 4-6 sales however as these are inter-trade there may not be many types of sale",
            "id": 317,
            "question": "How many transactions were audited of this type",
            "question_id": "11.3.3"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "TEXT",
            "category_id": 2,
            "guidance": "Please add reason for non-compliance as a corrective action",
            "id": 318,
            "question": "Were any sales to certificated users non-compliant",
            "question_id": "11.1.7"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 319,
            "question": "Are Sales Made to Farm Assurance Scheme members",
            "question_id": "11.2.1"
        },
        {
            "ResponseType": "Primary and Secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Not known",
                    "failscore": 20
                },
                {
                    "answer": "Agricultural Industries Confederation",
                    "failscore": 20
                },
                {
                    "answer": "British Egg Industry Council Code of Practice for Lion Eggs",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance - Beef & Lamb",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance - Dairy",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance - Crops",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance - Fresh Produce",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance -Pigs",
                    "failscore": 20
                },
                {
                    "answer": "Red Tractor Farm Assurance - Poultry",
                    "failscore": 20
                },
                {
                    "answer": "Quality Meat Scotland - Cattle & Sheep",
                    "failscore": 20
                },
                {
                    "answer": "Quality Meat Scotland - Pigs",
                    "failscore": 20
                },
                {
                    "answer": "Farm Assured Welsh Livestock - Beef & Lamb",
                    "failscore": 20
                },
                {
                    "answer": "Scottish Quality Crops",
                    "failscore": 20
                },
                {
                    "answer": "Northern Ireland Farm Quality Assurance Scheme - Beef & Lamb",
                    "failscore": 20
                },
                {
                    "answer": "Northern Ireland Farm Quality Assurance Scheme - Cereals",
                    "failscore": 20
                },
                {
                    "answer": "Quality British Turkey",
                    "failscore": 20
                },
                {
                    "answer": "Laid in Britain",
                    "failscore": 20
                },
                {
                    "answer": "Duck Assurance Scheme (Breeders Replacement, Breeders Layers, Hatcheries, Table Birds, Free Range Table Birds",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "The schemes listed are the only ones that are currently approved - check the CRRU website if unsure",
            "id": 320,
            "question": "Is there proof of membership of a scheme",
            "question_id": "11.2.2"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Date recorded",
                    "failscore": 20
                },
                {
                    "answer": "Date not recorded",
                    "failscore": 20
                },
                {
                    "answer": "Membership has expired",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Expiry dates of membership should be recorded and monitored to ensure sales are only made to current members.  Ideally on-line checkers are recommended but not compulsory",
            "id": 321,
            "question": "Is there an expiry/ renewal date",
            "question_id": "11.2.3"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Declaration compliant",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not available",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not signed",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show address of the farm or alternative delivery address",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show name of purchaser /collector",
                    "failscore": 20
                },
                {
                    "answer": "Declaration shows FA scheme details which have expired and not been revised",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "Farm Assurance Declarations must be used to identify delivery addresses and name persons approved to order / collect.  They do not need to be updated annually unless anything changes",
            "id": 322,
            "question": "Is there a Farm Assurance Declaration or equivalent",
            "question_id": "11.2.4"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No (+)",
                    "failscore": 30
                },
                {
                    "answer": "Yes (-)",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 2,
            "guidance": "A corrective action must be raised if any sales are non-compliant",
            "id": 323,
            "question": "Were any sales to Farm Assurance Scheme members non-compliant",
            "question_id": "11.2.6"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Yes",
                    "failscore": 0
                },
                {
                    "answer": "N/A",
                    "failscore": 0
                }
            ],
            "category_id": 4,
            "guidance": "",
            "id": 324,
            "question": "Are Sales made though the supply chain",
            "question_id": "11.3.1"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "MULTI",
            "answers": [
                {
                    "answer": "Declaration compliant",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not available",
                    "failscore": 20
                },
                {
                    "answer": "Declaration not signed",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show company address or alternative delivery address",
                    "failscore": 20
                },
                {
                    "answer": "Declaration does not show name of purchaser /collector",
                    "failscore": 20
                },
                {
                    "answer": "Declaration shows FA scheme details which have expired and not been revised",
                    "failscore": 20
                },
                {
                    "answer": "Contract in place - unseen",
                    "failscore": 20
                }
            ],
            "category_id": 2,
            "guidance": "A supply chain declaration must be used OR a contract in place to ensure stewardship requirements are noted",
            "id": 325,
            "question": "Is there a Supply Chain Partner Declaration or equivalent contract",
            "question_id": "11.3.2"
        },
        {
            "ResponseType": "Primary and secondary",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "No records available (Raise a corrective action)",
                    "failscore": 30
                },
                {
                    "answer": "Missing information (Raise a corrective action)",
                    "failscore": 30
                }
            ],
            "category_id": 2,
            "guidance": "A corrective action must be raised if any sales are non-compliant",
            "id": 326,
            "question": "Were any sales to supply chain non-compliant",
            "question_id": "11.3.4"
        },
        {
            "ResponseType": "Secondary Only",
            "anstype": "TIME",
            "category_id": 4,
            "guidance": "",
            "id": 327,
            "question": "What time was the Audit was completed",
            "question_id": "12.1.1"
        },
        {
            "ResponseType": "Primary Only",
            "anstype": "SINGLE",
            "answers": [
                {
                    "answer": "Option 1",
                    "failscore": 30,
                    "subquestion": [
                        6
                    ]
                }
            ],
            "category_id": 2,
            "guidance": "Guide",
            "id": 328,
            "question": "Mike Question"
        }
    ]

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];

    constructor() {
        this.dataCallback = this.getDataCallback()
        this.responseCallback = this.getResponseCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return QuestionApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }

    private getResponseCallback() {
        return ({ resBody, httpMethod }) => {

            if (httpMethod == 'GET') {

                if (resBody.length <= 1) {
                    resBody = resBody[0]
                }

                return new HttpResponse({
                    body: resBody,
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    url: 'api/register',
                    statusText: 'user verify',
                    status: 200,
                });

            }
            else if (httpMethod == 'POST') {
                console.log("entro post")
            }
        }
    }
}
