import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})
export class IconsComponent implements OnInit {
  
  @Input() data: any;

  color: string;
  icon: string;

  constructor() {}

  ngOnInit(): void {
    this.icon = this.data.icon;
    this.color = this.data.color;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue;
    this.icon = this.data.icon;
    this.color = this.data.color;
  }

  getSVG(){
    return ((this.icon)? 'assets/particles/icons/'+this.icon+'.svg':'');
  }

  click(){
    if(this.data.onClick){
      this.data.onClick();
    }
  }

}
