import { ApiMockRootRoute, ApiMockDataCallback, ObjectAny, ApiMockResponseCallback, ApiMockRoute } from "@ng-stack/api-mock";

export class InivteApiMockRootRoute implements ApiMockRootRoute {

    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];
    
    constructor() {

    }
}