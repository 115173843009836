<div class="container">
    
    <div class= "titleContainer">
        <div class="field1">
            <ng-container #field1></ng-container>
        </div>
        <div class="field2">
            <ng-container #field2></ng-container>
        </div>
    </div>
    
    <div class="field3">
        <ng-container #field3></ng-container>
    </div>

</div>

