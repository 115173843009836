import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, mergeMap, tap } from 'rxjs/operators';
import { API, HEADERS } from 'src/app/services/constants';
import { BehaviorSubject, combineLatest, Observable,  } from 'rxjs';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { AnswerOption, Question } from 'src/app/models/store/question';
import { StoreCategoryService } from 'src/app/services/store/store-category.service';
import { StoreIdentifierService } from './store-identifier.service';
import { Identifier } from 'src/app/models/store/identifier';
import { StoreEquationService } from './store-equation.service';
import { DataStoreService } from '../datastore.service';

@Injectable({
    providedIn: 'root'
})
export class StoreQuestionService extends DataStoreService<Question> {

    questionCreated: BehaviorSubject<Question> = new BehaviorSubject<Question>(null);
    answersOptions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        protected http: HttpClient,
        private storeCategoryService:StoreCategoryService,
        private storeIdentifierService: StoreIdentifierService,
        private storeEquationService: StoreEquationService,
    ) {
        super(http, 'questions')
    }

    getAll(apiRequest: ApiRequest<Question>): Observable<Question[]> {

        console.log('>> questions get all')
        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Question[]> => {

                console.log(response)

                if(!response) return new BehaviorSubject<Question[]>([])

                let questions: Observable<Question>[] = response.map((element): Observable<Question> => {

                    const mapper = new ApiRequestMapper<Question>(apiRequest);

                    mapper.setMapperKey('id', element.id);
                    mapper.setMapperKey('responseType', element.ResponseType);
                    mapper.setMapperKey('ansType', element.anstype);
                    mapper.setMapperKey('guidance', element.guidance);
                    mapper.setMapperKey('question', element.question);
                    mapper.setMapperKey('question_id', element.question_id);

                    let categoryApiRequest = apiRequest.getStructureChild('category')
                    if(categoryApiRequest){
                        mapper.setMapperKey('category', this.storeCategoryService.getById.bind(
                            this.storeCategoryService,
                            element.category_id,
                            categoryApiRequest
                        ));
                    }

                    let equationApiRequest = apiRequest.getStructureChild('equation')
                    if(equationApiRequest){
                        mapper.setMapperKey('equation', this.storeEquationService.getById.bind(
                            this.storeEquationService,
                            element.equation_id,
                            equationApiRequest
                        ));
                    }

                    let answersOptionsApiRequest = apiRequest.getStructureChild('answersOptions')
                    if(answersOptionsApiRequest){
                        mapper.setMapperKey('answersOptions', this.mapAnswersOptions.bind(
                            this,
                            element.answers,
                            answersOptionsApiRequest
                        ));
                    }

                    return mapper.map()
                })

                return combineLatest(questions)
            })
        )

    }

    getById(id: number, apiRequest: ApiRequest<Question>): Observable<Question> {

        console.log('>> questions get by id: ', id)
        return this.getDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<Question> => {

                let element = response.find(question =>  question.id == id)

                if(!element) return new BehaviorSubject<Question>(null)

                const mapper = new ApiRequestMapper<Question>(apiRequest);

                mapper.setMapperKey('id', element.id);
                mapper.setMapperKey('responseType', element.ResponseType);
                mapper.setMapperKey('ansType', element.anstype);
                mapper.setMapperKey('guidance', element.guidance);
                mapper.setMapperKey('question', element.question);
                mapper.setMapperKey('question_id', element.question_id);

                let categoryApiRequest = apiRequest.getStructureChild('category')
                if(categoryApiRequest){
                    mapper.setMapperKey('category', this.storeCategoryService.getById.bind(
                        this.storeCategoryService,
                        element.category_id,
                        categoryApiRequest
                    ));
                }

                let equationApiRequest = apiRequest.getStructureChild('equation')
                if(equationApiRequest){
                    mapper.setMapperKey('equation', this.storeEquationService.getById.bind(
                        this.storeEquationService,
                        element.equation_id,
                        equationApiRequest
                    ));
                }

                let answersOptionsApiRequest = apiRequest.getStructureChild('answersOptions')
                if(answersOptionsApiRequest){
                    mapper.setMapperKey('answersOptions', this.mapAnswersOptions.bind(
                        this,
                        element.answers,
                        answersOptionsApiRequest
                    ));
                }

                return mapper.map();

            })
        )
    }

    private mapAnswersOptions(answersOptions: any, apiRequest: ApiRequest<AnswerOption>): Observable<AnswerOption[]>{

        if(!answersOptions) return new BehaviorSubject<AnswerOption[]>(null).asObservable()

        let answersOptionsMapped: Observable<AnswerOption>[] = answersOptions.map((answerOption): Observable<AnswerOption> => {

            const mapper = new ApiRequestMapper<AnswerOption>(apiRequest)

            mapper.setMapperKey('answer', answerOption.answer);
            mapper.setMapperKey('failscore', answerOption.failscore);

            let subquestions = null

            if(answerOption.subquestion){
                let subquestionsObservables: Observable<Question>[] = answerOption.subquestion.map((subquestion): Observable<Question> => {
                    return this.getById(
                        subquestion,
                        apiRequest.getStructureChild('subquestions')
                    )
                })
                subquestions = (() => combineLatest(subquestionsObservables)).bind(this);
            }

            mapper.setMapperKey('subquestions', subquestions);

            return mapper.map();

        })

        return combineLatest(answersOptionsMapped);

    }

    create(question: Question){

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((response: any): Observable<any> => {
                return this.storeIdentifierService.increaseAndGetByName('questions').pipe(
                    mergeMap((identifier: Identifier) => {

                        let answersOptions
                        if (question.answersOptions && question.answersOptions.length > 0){
                            answersOptions = question.answersOptions.map(option => {
                                let answersOption = {
                                    answer: option.answer,
                                    failscore: option.failscore,
                                    subquestion: option.subquestions? option.subquestions.map(question => question.id) : null
                                }

                                Object.keys(answersOption).forEach(key => answersOption[key] !== 0 && !answersOption[key] ? delete answersOption[key] : null);

                                return answersOption
                            })
                        }else{
                            answersOptions = null
                        }

                        let questionData = {
                            id: identifier.currentValue,
                            anstype: question.ansType,
                            category_id: question.category.id,
                            equation_id: question.equation? question.equation.id: null,
                            guidance: question.guidance,
                            question: question.question,
                            question_id: question.question_id,
                            answers: answersOptions
                        }

                        Object.keys(questionData).forEach(key => !questionData[key] ? delete questionData[key] : null);

                        response.push(questionData)

                        console.log(questionData)

                        return this.http.post (
                            API + '/datastore/questions',
                            response,
                            {
                                headers: HEADERS
                            }
                        )
                    }),
                    tap(() => {
                        this.setDataStore(response);
                    })
                )
            })
        )
    }

    deleteById(id: number){

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let datastoreFiltered = datastore.filter(question => question.id != id)

                return this.http.post (
                    API + '/datastore/questions',
                    datastoreFiltered,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastoreFiltered);
                    })
                )
            })
        )
    }

    updateById(id: number, question: Question){

        return this.fetchDataStore().pipe(
            first(),
            mergeMap((datastore: any): Observable<any> => {
                let questionData  = datastore.find(question => question.id == id)
                let answersOptions
                if (question.answersOptions && question.answersOptions.length > 0){
                    answersOptions = question.answersOptions.map(option => {
                        let answersOption = {
                            answer: option.answer,
                            failscore: option.failscore,
                            subquestion: option.subquestions? option.subquestions.map(question => question.id) : null
                        }

                        Object.keys(answersOption).forEach(key => answersOption[key] !== 0 && !answersOption[key] ? delete answersOption[key] : null);
                        return answersOption
                    })
                }
                else{
                    answersOptions = null
                }

                questionData.anstype = question.ansType
                questionData.category_id = question.category.id,
                questionData.equation_id = question.equation? question.equation.id: null,
                questionData.guidance = question.guidance
                questionData.question = question.question
                questionData.question_id = question.question_id
                questionData.answers = answersOptions

                Object.keys(questionData).forEach(key => !questionData[key] ? delete questionData[key] : null);

                console.log(questionData)

                return this.http.post (
                    API + '/datastore/questions',
                    datastore,
                    {
                        headers: HEADERS
                    }
                ).pipe(
                    tap(() => {
                        this.setDataStore(datastore);
                    })
                )
            })
        )
    }

    setQuestionCreated(question: Question ){
        this.questionCreated.next(question);
    }

    getQuestionCreated(): Question{
        return this.questionCreated.getValue();
    }

    setAnswersOptions(answers: any[] ){
        this.answersOptions.next(answers);
    }

    getAnswersOptions(): any[] {
        return this.answersOptions.getValue();
    }

}
