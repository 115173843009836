import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentItem } from 'src/app/interfaces/provider';

@Component({
  selector: 'app-dashboard-item-layout',
  templateUrl: './dashboard-item-layout.component.html',
  styleUrls: ['./dashboard-item-layout.component.css']
})
export class DashboardItemLayoutComponent {

  @Input() field1: ComponentItem;
  @Input() field2: ComponentItem;
  @Input() field3: ComponentItem;
  
  @ViewChild('field1', { read: ViewContainerRef }) fieldContainer1: ViewContainerRef;
  @ViewChild('field2', { read: ViewContainerRef }) fieldContainer2: ViewContainerRef;
  @ViewChild('field3', { read: ViewContainerRef }) fieldContainer3: ViewContainerRef;


  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngAfterViewInit() {
    
    setTimeout(() => {
      
      const fieldResolver1 = this.componentFactoryResolver.resolveComponentFactory(this.field1.component);
      const componentRef1 = this.fieldContainer1.createComponent(fieldResolver1);
      componentRef1.instance.data = this.field1.data;

      const fieldResolver2 = this.componentFactoryResolver.resolveComponentFactory(this.field2.component);
      const componentRef2 = this.fieldContainer2.createComponent(fieldResolver2);
      componentRef2.instance.data = this.field2.data;

     
      const fieldResolver3 = this.componentFactoryResolver.resolveComponentFactory(this.field3.component);
      const componentRef3 = this.fieldContainer3.createComponent(fieldResolver3);
      componentRef3.instance.data = this.field3.data;
    
    });
  }

}
