import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit{

  @Input() data: any;

  buttonData: any;

  ngOnInit(): void {
    if(this.data.inputInfo){
      this.buttonData = this.data.inputInfo
    }
    else{
      this.buttonData = this.data
    }
  }

  click(){
    if(this.data.inputInfo) {
      this.buttonData.onSubmit(this.data.form)
    }
    else{
      this.buttonData.onSubmit()
    }
  }

  disable(){
    if(this.buttonData.enable === null || this.buttonData.enable === undefined || this.buttonData.enable) return null
    return true
  }

}
