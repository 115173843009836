<div class="container background-{{theme}}">
    <div class="testingTitle">
        <h1 class="t1-36-bold text-{{theme}}">Component under testing</h1>
        <div class="right-wrapper" style="width: 115px">
            <div class="form-item">
              <input class="checkbox-right" type="checkbox" id="darkMode" (change)="setTheme()" [attr.checked]="theme === 'dark'? null : true">
              <label class="t1-18" for="darkMode">Dark Mode</label>
            </div>
          </div>
    </div>

    <!-- Testing Component Here -->
    <app-dashboard
      [data]="dashboardItems"
    ></app-dashboard>
    <!-- Testing Component Here -->
</div> 
