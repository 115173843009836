import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private active: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private hidden: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  setActive(active: string){
    if(active !== this.active.getValue()){
      this.active.next(active);
    }
  }
 
  getActive(): Observable<string>{
    return this.active.asObservable();
  }

  setHidden(hidden: boolean ){
    this.hidden.next(hidden);
  }
 
  /* getHidden(): Observable<boolean>{
    return this.hidden.asObservable();
  } */


  getHidden(): boolean{
    return this.hidden.getValue();
  } 


}
