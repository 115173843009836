import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestingComponent } from './components/UI/atoms/testing/testing.component';
import { IsAuthenticatedGuard } from './guards/is-authenticated.guard';
import { IsNotRegisteredGuard } from './guards/is-not-registered.guard';
import { IsUserLoginGuard } from './guards/is-user-login.guard';

const routes: Routes = [
  { path: 'testing', component: TestingComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [IsUserLoginGuard],
    loadChildren: () => import('./components/pages/public/login-page/login-page.module').then(m => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./components/pages/public/forgot-password-page/forgot-password-page.module').then(
        m => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'set-password',
    loadChildren: () =>
      import('./components/pages/public/set-password-page/set-password-page.module').then(m => m.SetPasswordPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./components/pages/public/set-password-page/set-password-page.module').then(m => m.SetPasswordPageModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./components/pages/public/register-page/register-page.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'migration',
    loadChildren: () =>
      import('./components/pages/public/confirm-information/confirm-information.module').then(
        m => m.ConfirmInformationModule
      ),
  },
  {
    path: 'app',
    canActivate: [IsAuthenticatedGuard],
    loadChildren: () =>
      import('./components/pages/private/main-app-page/main-app-page.module').then(m => m.MainAppPageModule),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

//canActivate: [IsNotAuthenticatedGuard],
//, canActivate: [IsUserIdLoginPageGuard]
// , canActivate: [IsUserIdLoginPageGuard]
