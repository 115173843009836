import { HttpResponse, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { ApiMockDataCallback, ApiMockResponseCallback, ApiMockRootRoute, ApiMockRoute, ObjectAny } from "@ng-stack/api-mock"
import { AccountApiMockRootRoute } from "./account-api-mock-root-route";

export class OrganisationApiMockRootRoute implements ApiMockRootRoute {

    static DB = [
        {
            "attrs": {
                "address": "Mars 2030",
                "campaign": "Spain",
                "companynumber": "12345678",
                "country": "GB",
                "name": "Space X",
                "oldbasisid": 10004019,
                "postalcode": "E1 6AN",
                "regnum": "6055"
            },
            "created": "2021-08-09T20:43:08+01:00",
            "id": 43,
            "updated": null
        }
    ]
    host?: string;
    path: string;
    dataCallback?: ApiMockDataCallback<ObjectAny[], ObjectAny[]>;
    propertiesForList?: ObjectAny;
    responseCallback?: ApiMockResponseCallback<ObjectAny[], ObjectAny[]>;
    ignoreDataFromLocalStorage?: boolean;
    children?: ApiMockRoute[];
    
    constructor() {
        this.dataCallback = this.getDataCallback();
    }

    private getDataCallback(): ApiMockDataCallback {
        return ({ httpMethod, items }) => {
            if (httpMethod == 'GET') {
                return OrganisationApiMockRootRoute.DB;
            } else {
                return items;
            }
        }
    }
}