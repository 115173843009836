import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApiRequest, ApiRequestMapper } from 'src/app/models/apiRequest';
import { Equation, Field } from 'src/app/models/store/equation';
import { DataStoreService } from '../datastore.service';

@Injectable({
  providedIn: 'root'
})
export class StoreEquationService extends DataStoreService<Equation> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, 'equations')
  }

  getAll(apiRequest: ApiRequest<Equation>): Observable<Equation[]> {

    console.log('>> equations get all')
    return this.getDataStore().pipe(
      mergeMap((response: any): Observable<Equation[]> => {

        if (!response) return new BehaviorSubject<Equation[]>([])

        let equations: Observable<Equation>[] = response.map((element): Observable<Equation> => {

          const mapper = new ApiRequestMapper<Equation>(apiRequest);

          mapper.setMapperKey('id', element.id);
          mapper.setMapperKey('name', element.name);
          mapper.setMapperKey('formula', element.formula);
          mapper.setMapperKey('units', element.units);
          mapper.setMapperKey('decimalPlaces', element.decimal_places);

          let fields = (() => this.mapField(element.fields , apiRequest.getStructureChild('fields'))).bind(this);

          mapper.setMapperKey('fields', fields);

          return mapper.map();

        })

        return combineLatest(equations)
      })
    )

  }

  getById(id: number, apiRequest: ApiRequest<Equation>): Observable<Equation> {

    console.log('>> equations get by id: ', id)
    return this.getDataStore().pipe(
      first(),
      mergeMap((response: any): Observable<Equation> => {

        let element = response.find(equation => equation.id == id)

        if (!element) return new BehaviorSubject<Equation>(null)

        const mapper = new ApiRequestMapper<Equation>(apiRequest);

        mapper.setMapperKey('id', element.id);
        mapper.setMapperKey('name', element.name);
        mapper.setMapperKey('formula', element.formula);
        mapper.setMapperKey('units', element.units);
        mapper.setMapperKey('decimalPlaces', element.decimal_places);

        let fields = (() => this.mapField(element.fields , apiRequest.getStructureChild('fields'))).bind(this);

        mapper.setMapperKey('fields', fields);

        return mapper.map();

      })
    )
  }

  private mapField(fields: any, apiRequest: ApiRequest<Field>): Observable<Field[]> {

    if(!fields || fields.length == 0) return new BehaviorSubject<Field[]>(null).asObservable()

    let fieldsObservable: Observable<Field>[] =  fields.map((field): Observable<Field> => {

        const mapper = new ApiRequestMapper<Field>(apiRequest)

        mapper.setMapperKey('name', field.name);
        mapper.setMapperKey('units', field.units);

        return mapper.map();
    })

    return combineLatest(fieldsObservable);
  }

}
